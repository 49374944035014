import { useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Box, IconButton, Link, Theme, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { dispatch, useSelector } from 'store';
import { closeTemporaryDrawer } from 'store/reducers/temporaryDrawer';
import TabsGallery, { TabType } from 'components/TabsGallery';
import { MessageDetailsDto } from 'types/chat';
import { t } from 'utils/getTranslations';
import CountBox from 'layout/MainLayout/Header/CountBox';
import {
    useArchiveMessagesMutation,
    useReadMessagesMutation,
    useReadNotificationMutation,
} from 'store/api/notifications';
import { NotificationCategory } from 'types/notifications';
import ChatMessageList from './ChatMessageList';
import AddressedTasksList from './AddressedTasks/AddressedTasksList';
import { AddressedTaskListItem } from 'types/tasks';
import { useNavigateWithReturnState } from 'utils/useNavigateWithReturnState';

export interface Props {
    messages: MessageDetailsDto[];
    tasks: AddressedTaskListItem[];
    theme: Theme;
    opennAddressTaskView: (taskId: number) => void;
    toggleDrawer: () => void;
}

const TasksDrawerContent: React.FC<Props> = ({ messages, tasks, theme, opennAddressTaskView, toggleDrawer }) => {
    const userId = useSelector((store) => store.user.id);
    const navigate = useNavigateWithReturnState();

    const [readNotification] = useReadNotificationMutation();
    const [readMessages] = useReadMessagesMutation();
    const [archiveMessages] = useArchiveMessagesMutation();

    const { buttonsDisabled: areButtonsDisabled } = useSelector((state) => state.buttons);
    const unreadMessages = messages?.filter((val) => !val.readAt).length ?? 0;

    const openTasksPage = () => {
        toggleDrawer();
        navigate('/tasks');
    };

    const handleRead = useCallback(async (idList: number[], type: NotificationCategory): Promise<void> => {
        if (idList.length === 1) {
            await readNotification({ id: idList[0] }).unwrap().then().catch(console.error);
        } else {
            await readMessages({ type, idList }).unwrap().then().catch(console.error);
        }
    }, []);

    const handleArchive = useCallback(async (idList: number[], type: NotificationCategory): Promise<void> => {
        await archiveMessages({ type, idList }).unwrap().then().catch(console.error);
    }, []);

    const closeDrawer = useCallback(() => {
        dispatch(closeTemporaryDrawer());
    }, []);

    const TABS: TabType[] = useMemo(() => {
        return [
            {
                id: 'tasks',
                label: t('tasks.tasks'),
                component: (
                    <AddressedTasksList
                        theme={theme}
                        tasks={tasks}
                        userId={userId}
                        openAddressedTaskView={opennAddressTaskView}
                        toggleDrawer={toggleDrawer}
                    />
                ),
                sx: { height: '100%', overflow: 'auto' },
            },
            {
                id: 'chat',
                label: t('tasks.chat'),
                component: (
                    <ChatMessageList
                        messages={messages}
                        unread={unreadMessages}
                        theme={theme}
                        userId={userId}
                        handleRead={(idList) => handleRead(idList, NotificationCategory.INVOICING)}
                        handleArchive={(idList) => handleArchive(idList, NotificationCategory.INVOICING)}
                        areButtonsDisabled={areButtonsDisabled}
                    />
                ),
                sx: { height: '100%', overflow: 'auto' },
                icon: <CountBox>{unreadMessages}</CountBox>,
            },
        ];
    }, [messages, handleArchive, handleRead, areButtonsDisabled]);

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
            <Box sx={{ padding: '20px 24px 4px', display: 'flex', justifyContent: 'space-between' }}>
                <Typography sx={{ fontWeight: 600, fontSize: 16 }}>
                    <FormattedMessage id="tasks.tasks" />
                </Typography>
                <Box>
                    <Link
                        sx={{
                            color: 'text.link',
                            fontWeight: 400,
                            fontSize: 14,
                            justifyContent: 'center',
                            alignItems: 'center',
                            textDecoration: 'none',
                            ml: '15px',
                            ':hover': { textDecoration: 'none' },
                        }}
                        onClick={openTasksPage}
                    >
                        <FormattedMessage id="tasks.view-form.all-tasks" />
                    </Link>
                    <IconButton aria-label="close notifications" onClick={closeDrawer}>
                        <CloseIcon />
                    </IconButton>
                </Box>
            </Box>

            <TabsGallery
                wrapperSx={{
                    display: 'grid',
                    gridTemplateRows: '1fr auto',
                    height: '100%',
                    overflow: 'hidden',
                }}
                sx={{ mb: 0, padding: '0px 8px' }}
                tabs={TABS}
            />
        </Box>
    );
};

export default TasksDrawerContent;
