// third-party
import { combineReducers } from 'redux';

// project import
import chat from './chat';
import menu from './menu';
import snackbar from './snackbar';
import user from './user';
import subDrawer from './subdrawer';
import temporaryDrawer from './temporaryDrawer';
import timecard from './timecard';
import buttons from './buttons';

import { portalAPI } from 'store/api/portalAPI';
import { AnyAction } from '@reduxjs/toolkit';

// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({
    chat,
    menu,
    snackbar,
    user,
    subDrawer,
    temporaryDrawer,
    timecard,
    buttons,
    [portalAPI.reducerPath]: portalAPI.reducer,
});

export type RootState = ReturnType<typeof reducers>;

const rootReducer = (state: RootState, action: AnyAction) => {
    // Reset the store after logout
    if (action.type === 'user/logout') {
        state = {} as RootState;
    }
    return reducers(state, action);
};

export default rootReducer;
