import {
    InvoiceTimecardsAndExpensesDto,
    InvoiceTimecard,
    InvoiceTimecardUpdateDto,
    InvoiceExpenseUpdateDto,
    InvoiceExpense,
    InvoiceMatterSummary,
    InvoiceMatterUpdateDto,
    LimitType,
    InvoiceAdjustmentDto,
} from 'types/invoice-details';
import { portalAPI } from './portalAPI';
import { APIMattersSlice } from './matters';
import { APIInvoicesSlice } from './invoices';

export const APIInvoiceDetailsSlice = portalAPI.enhanceEndpoints({ addTagTypes: ['InvoiceMatters'] }).injectEndpoints({
    endpoints: (builder) => ({
        getInvoiceTimecardsAndExpenses: builder.query<InvoiceTimecardsAndExpensesDto, number>({
            query: (invoiceId) => `/v1/invoice-details/${invoiceId}`,
            keepUnusedDataFor: 0,
        }),
        getInvoiceMatterSummary: builder.query<InvoiceMatterSummary, { invoiceId: number; matterId: number }>({
            query: ({ invoiceId, matterId }) => `/v1/invoice-details/${invoiceId}/matters/${matterId}`,
            providesTags: (_1, _2, arg) => [{ type: 'InvoiceMatters', invoiceMatter: arg }],
            keepUnusedDataFor: 0,
        }),
        updateInvoiceMatter: builder.mutation<InvoiceMatterSummary, InvoiceMatterUpdateDto>({
            query: ({ invoiceId, matterId, ...body }) => ({
                url: `/v1/invoice-details/${invoiceId}/matters/${matterId}`,
                method: 'PUT',
                body,
            }),
            invalidatesTags: (_1, _2, arg) => [
                { type: 'InvoiceMatters', invoiceMatter: { invoiceId: arg.invoiceId, matterId: arg.matterId } },
            ],
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                await queryFulfilled;
                dispatch(APIMattersSlice.util.invalidateTags(['MatterSummary']));
                dispatch(
                    APIInvoicesSlice.util.invalidateTags([
                        { type: 'InvoiceTotals', id: arg.invoiceId },
                        { type: 'Invoices', id: arg.invoiceId },
                    ])
                );
            },
        }),
        updateInvoiceAdjustments: builder.mutation<void, { invoiceId: number; body: InvoiceAdjustmentDto }>({
            query: ({ invoiceId, body }) => ({
                url: `/v1/invoices/${invoiceId}/adjustments`,
                method: 'PUT',
                body,
            }),
            invalidatesTags: ['InvoiceMatters'],
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                await queryFulfilled;
                dispatch(APIMattersSlice.util.invalidateTags(['MatterSummary']));
                dispatch(
                    APIInvoicesSlice.util.invalidateTags([
                        { type: 'InvoiceTotals', id: arg.invoiceId },
                        { type: 'Invoices', id: arg.invoiceId },
                    ])
                );
            },
        }),

        updateInvoiceTimecard: builder.mutation<InvoiceTimecard, InvoiceTimecardUpdateDto>({
            query: ({ invoiceId, id, ...body }) => ({
                url: `/v1/invoice-details/${invoiceId}/timecard/${id}`,
                method: 'PUT',
                body,
            }),
            invalidatesTags: ['InvoiceMatters'],
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                await queryFulfilled;
                dispatch(APIMattersSlice.util.invalidateTags(['MatterSummary']));
                dispatch(
                    APIInvoicesSlice.util.invalidateTags([
                        { type: 'InvoiceTotals', id: arg.invoiceId },
                        { type: 'Invoices', id: arg.invoiceId },
                    ])
                );
            },
        }),
        updateInvoiceExpense: builder.mutation<InvoiceExpense, InvoiceExpenseUpdateDto>({
            query: ({ invoiceId, id, ...body }) => ({
                url: `/v1/invoice-details/${invoiceId}/expense/${id}`,
                method: 'PUT',
                body,
            }),
            invalidatesTags: ['InvoiceMatters'],
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                await queryFulfilled;
                dispatch(APIMattersSlice.util.invalidateTags(['MatterSummary']));
                dispatch(
                    APIInvoicesSlice.util.invalidateTags([
                        { type: 'InvoiceTotals', id: arg.invoiceId },
                        { type: 'Invoices', id: arg.invoiceId },
                    ])
                );
            },
        }),
        addNewTimecards: builder.mutation<number[], { invoiceId: number; limit: LimitType }>({
            query: ({ invoiceId, limit }) => ({
                url: `/v1/invoice-details/${invoiceId}/add-new-timecards?limit=${limit}`,
                method: 'PUT',
            }),
            invalidatesTags: ['InvoiceMatters'],
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                await queryFulfilled;
                dispatch(APIMattersSlice.util.invalidateTags(['MatterSummary']));
                dispatch(
                    APIInvoicesSlice.util.invalidateTags([
                        { type: 'InvoiceTotals', id: arg.invoiceId },
                        { type: 'Invoices', id: arg.invoiceId },
                    ])
                );
            },
        }),
        addNewExpenses: builder.mutation<number[], { invoiceId: number; limit: LimitType }>({
            query: ({ invoiceId, limit }) => ({
                url: `/v1/invoice-details/${invoiceId}/add-new-expenses?limit=${limit}`,
                method: 'PUT',
            }),
            invalidatesTags: ['InvoiceMatters'],
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                await queryFulfilled;
                dispatch(APIMattersSlice.util.invalidateTags(['MatterSummary']));
                dispatch(
                    APIInvoicesSlice.util.invalidateTags([
                        { type: 'InvoiceTotals', id: arg.invoiceId },
                        { type: 'Invoices', id: arg.invoiceId },
                    ])
                );
            },
        }),
        updateTimecardsHourlyRates: builder.mutation<void, { invoiceId: number; employeeChanged: boolean }>({
            query: ({ invoiceId, employeeChanged }) => ({
                url: `/v1/invoice-details/${invoiceId}/update-timecards-hourly-rates${
                    employeeChanged ? '?newEmployeeOnly=true' : ''
                }`,
                method: 'PUT',
            }),
            invalidatesTags: ['InvoiceMatters'],
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                await queryFulfilled;
                dispatch(APIMattersSlice.util.invalidateTags(['MatterSummary']));
                dispatch(
                    APIInvoicesSlice.util.invalidateTags([
                        { type: 'InvoiceTotals', id: arg.invoiceId },
                        { type: 'Invoices', id: arg.invoiceId },
                    ])
                );
            },
        }),
    }),
    overrideExisting: false,
});

export const {
    useUpdateInvoiceExpenseMutation,
    useUpdateInvoiceTimecardMutation,
    useGetInvoiceTimecardsAndExpensesQuery,
    useGetInvoiceMatterSummaryQuery,
    useUpdateInvoiceMatterMutation,
    useUpdateInvoiceAdjustmentsMutation,
    useAddNewTimecardsMutation,
    useAddNewExpensesMutation,
    useUpdateTimecardsHourlyRatesMutation,
} = APIInvoiceDetailsSlice;
