import est from 'utils/locales/et.json';
import eng from 'utils/locales/en.json';

import { createIntl, createIntlCache, IntlShape } from 'react-intl';
import { getLocalStorage } from 'utils/localStorage';
import { ClassifierData, ClassifierText, EntityClassifierValue } from 'types/classifiers';

interface LocaleRecord {
    [key: string]: string | LocaleRecord;
}

interface Translations {
    [key: string]: LocaleRecord;
}

const translations: Translations = {
    est,
    eng,
};

const cache = createIntlCache();

export const getIntl = (): IntlShape => {
    const locale = getLocalStorage('locale') || 'eng';

    const intl = createIntl(
        {
            locale,
            // @ts-ignore
            messages: flattenMessages(translations[locale]),
        },
        cache
    );

    return intl;
};

export const t = (id: string, values?: { [key: string]: string }): string => {
    return getIntl().formatMessage({ id }, { ...values });
};

export const getClassifierText = (classifier: ClassifierData | undefined, typeCode: string | undefined): string => {
    const locale: string = getIntl().locale;
    const classifierValue: EntityClassifierValue | undefined = classifier?.values?.find(
        (value) => value.code === typeCode
    );

    return (
        classifierValue?.texts.find(
            (text: ClassifierText) => text.langCode.toLocaleLowerCase() === locale.toLocaleLowerCase()
        )?.value ||
        classifierValue?.texts.find((text: ClassifierText) => text.langCode.toLocaleLowerCase() === 'eng')?.value ||
        typeCode ||
        ''
    );
};

export const getClassifierTextDescription = (
    classifier: ClassifierData | undefined,
    typeCode: string | undefined
): string => {
    const locale: string = getIntl().locale;
    const classifierValue: EntityClassifierValue | undefined = classifier?.values?.find(
        (value) => value.code === typeCode
    );

    return (
        classifierValue?.texts.find(
            (text: ClassifierText) => text.langCode.toLocaleLowerCase() === locale.toLocaleLowerCase()
        )?.description ||
        classifierValue?.texts.find((text: ClassifierText) => text.langCode.toLocaleLowerCase() === 'eng')
            ?.description ||
        typeCode ||
        ''
    );
};
export const getClassifierDescription = (classifier: ClassifierData | undefined): string => {
    if (classifier?.values == undefined) {
        return '';
    }
    const locale: string = getIntl().locale;
    let classifierDescription = '';
    let classifierText;
    const sortedValues = [...classifier.values].sort((a, b) => a.code.localeCompare(b.code));
    sortedValues?.forEach((value) => {
        classifierText = value.texts.find((text) => text.langCode.toLocaleLowerCase() === locale.toLocaleLowerCase());
        if (classifierText === undefined) {
            classifierDescription += `*N/A* - N/A\n`;
            return;
        }
        classifierDescription += `*${classifierText.value}* - ${classifierText.description}\n`;
    });
    return classifierDescription;
};
// react-intl requires single-level object
export const flattenMessages = (nestedMessages: LocaleRecord | undefined, prefix = '') => {
    if (nestedMessages === null || nestedMessages === undefined) {
        return {};
    }
    return Object.keys(nestedMessages).reduce((messages, key) => {
        const value = nestedMessages[key as keyof Locale];
        const prefixedKey = prefix ? `${prefix}.${key}` : key;

        if (typeof value === 'string') {
            Object.assign(messages, { [prefixedKey]: value });
        } else {
            if (value) {
                Object.assign(messages, flattenMessages(value, prefixedKey));
            }
        }

        return messages;
    }, {});
};
