export function callback<T>(fn: (callback: (err: any, result: T) => void) => void): Promise<T> {
    return new Promise((resolve, reject) => {
        fn((err, result) => {
            if (err) {
                return reject(err);
            }
            resolve(result);
        });
    });
}
