import {
    SnackbarProvider,
    closeSnackbar,
    CustomContentProps,
    SnackbarContent,
    SnackbarKey,
    SnackbarMessage,
} from 'notistack';
import React from 'react';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import PriorityHighRoundedIcon from '@mui/icons-material/PriorityHighRounded';
import { CloseOutlined } from '@ant-design/icons';
import IconButton from 'components/@extended/IconButton';
import { Alert } from '@mui/material';

// ==============================|| SNACKBAR PROVIDER ||============================== //

const SnackbarAlertProvider = ({ children }: { children: string | JSX.Element[] | JSX.Element }): JSX.Element => {
    const renderAlert = (id: SnackbarKey, message: SnackbarMessage, type: 'success' | 'error') => (
        <Alert
            key="new-timecards-added"
            variant="filled"
            severity={type}
            color={type}
            iconMapping={{
                error: <PriorityHighRoundedIcon color="error" />,
                success: <DoneRoundedIcon color="primary" />,
            }}
            action={
                <IconButton
                    sx={{ ml: '50px' }}
                    size="small"
                    aria-label="close"
                    variant="contained"
                    onClick={() => closeSnackbar(id)}
                >
                    <CloseOutlined />
                </IconButton>
            }
        >
            {message}
        </Alert>
    );

    const ErrorAlert = React.forwardRef<HTMLDivElement, CustomContentProps>((props, ref) => {
        const { id, message, ...other } = props;

        return (
            <SnackbarContent ref={ref} role="alert" {...other}>
                {renderAlert(id, message, 'error')}
            </SnackbarContent>
        );
    });

    const SuccessAlert = React.forwardRef<HTMLDivElement, CustomContentProps>((props, ref) => {
        const { id, message, ...other } = props;

        return (
            <SnackbarContent ref={ref} role="alert" {...other}>
                {renderAlert(id, message, 'success')}
            </SnackbarContent>
        );
    });

    return (
        <SnackbarProvider
            autoHideDuration={12000}
            maxSnack={10}
            Components={{
                error: ErrorAlert,
                success: SuccessAlert,
            }}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
        >
            {children}
        </SnackbarProvider>
    );
};

export default SnackbarAlertProvider;
