import { Box, Grid, Link, Stack, Typography, useTheme } from '@mui/material';
import LinkWithOrigin from 'components/util/LinkWithOrigin';
import { Link as RouterLink } from 'react-router-dom';
import { ClassifierData } from 'types/classifiers';
import { TimecardListItemData } from 'types/timecards';
import LabelledData from './LabelledData';
import TimecardActionButtons from './TimecardActionButtons';
import { TimecardEmployeeLabel } from './labels/TimecardEmployeeLabel';
import { getClassifierText, t } from 'utils/getTranslations';
import MoneyDisplay from 'components/util/MoneyDisplay';
import HoursDisplay from 'components/util/HoursDisplay';
import { TimecardStatusLabel } from './labels/TimecardStatusLabel';
import { useClassifiers } from 'utils/useClassifiers';

type Props = {
    timecard: TimecardListItemData;
    classifier: ClassifierData;
    compact?: boolean;
};

const boldStyle = { fontWeight: 600 };

const TimecardListItem = ({ timecard, classifier, compact }: Props): JSX.Element => {
    const { classifiers, isLoading } = useClassifiers({
        codes: ['TIMECARD_ACTIVITY_TYPE'],
    });
    const theme = useTheme();
    const expenseList = timecard?.timecardStats?.expenseList || [];
    const getTimecardLink = (): JSX.Element => {
        const linkColor = theme.palette.text.link;
        if (timecard.isEditable) {
            return (
                <Link color={linkColor} component={RouterLink} to={`/timecards/${timecard.id}/edit`}>
                    {t('timecards.edit-timecard')}
                </Link>
            );
        } else {
            return (
                <Link color={linkColor} component={RouterLink} to={`/timecards/${timecard.id}`}>
                    {t('timecards.view-timecard')}
                </Link>
            );
        }
    };

    return (
        <Grid container padding="16px">
            <Grid item md={compact ? 8 : 5} xs={compact ? 6 : 5}>
                <Stack>
                    <Link
                        component={LinkWithOrigin}
                        to={`/matter/${timecard.matter.id}`}
                        sx={{
                            ':hover': {
                                color: theme.palette.text.link,
                            },
                        }}
                    >
                        <Typography variant="subtitle1">{timecard.matter.fullName}</Typography>
                    </Link>
                    {timecard.matter.clientParty && (
                        <Link component={LinkWithOrigin} to={`/contact/${timecard.matter.clientParty.id}`}>
                            <Typography
                                variant="caption"
                                color={theme.palette.text.secondary}
                                sx={{
                                    ':hover': {
                                        color: theme.palette.text.link,
                                    },
                                }}
                            >
                                {timecard.matter.clientParty.name}
                            </Typography>
                        </Link>
                    )}
                    {!compact && (
                        <Box sx={{ display: 'flex', gap: 0.5 }}>
                            <TimecardStatusLabel
                                theme={theme}
                                classifier={classifier}
                                statusCode={timecard.timecardStatusTypeCode}
                            />
                            <TimecardEmployeeLabel name={timecard.employee.name} />
                        </Box>
                    )}
                    {!compact && (
                        <Stack direction="row" marginTop="16px" flexWrap="wrap" sx={{ '& > div': { marginRight: 1 } }}>
                            {timecard.matterAssignment?.name !== null &&
                                timecard?.matterAssignment?.name !== undefined && (
                                    <LabelledData label="timecards.list.matter-assignment" sx={boldStyle}>
                                        {timecard?.matterAssignment?.name}
                                    </LabelledData>
                                )}
                            {timecard?.timecardActivityTypeCode !== null &&
                                timecard?.timecardActivityTypeCode !== undefined && (
                                    <LabelledData label="timecards.list.activity-type" sx={boldStyle}>
                                        {getClassifierText(
                                            classifiers['TIMECARD_ACTIVITY_TYPE'],
                                            timecard?.timecardActivityTypeCode
                                        )}
                                    </LabelledData>
                                )}
                            <LabelledData label="timecards.list.total-time" sx={boldStyle}>
                                <HoursDisplay value={timecard?.timecardStats?.totalTimeInHours} />
                            </LabelledData>
                            <LabelledData label="timecards.list.billable-time" sx={boldStyle}>
                                <HoursDisplay value={timecard?.timecardStats?.billedTimeInHours} />
                            </LabelledData>
                            <LabelledData label="timecards.list.expenses" sx={boldStyle}>
                                {expenseList.length ? (
                                    expenseList.map((expenseMap, index) => {
                                        const optionalComma = index < expenseList.length - 1 ? `,\u00A0` : null;

                                        return Object.keys(expenseMap).map((currencyKey) => (
                                            <span key={currencyKey}>
                                                <MoneyDisplay amount={expenseMap[currencyKey]} currency={currencyKey} />
                                                {optionalComma}
                                            </span>
                                        ));
                                    })
                                ) : (
                                    <span style={boldStyle}>{` -`}</span>
                                )}
                            </LabelledData>
                            <LabelledData label="timecards.list.total-amount">
                                <span style={boldStyle}>
                                    <MoneyDisplay
                                        amount={timecard?.timecardStats?.amountInOriginalCurrency || 0}
                                        currency={timecard.timecardStats?.originalCurrencyTypeCode}
                                    />
                                </span>
                                <span style={{ marginLeft: '5px' }}>
                                    (
                                    <MoneyDisplay
                                        amount={timecard?.currencyDetails?.totalAmountInBaseCurrency || 0}
                                        currency={timecard?.currencyDetails?.baseCurrency}
                                    />
                                    )
                                </span>
                            </LabelledData>
                        </Stack>
                    )}
                    {compact && (
                        <Stack gap={1} pt={1}>
                            <Typography>{timecard.description}</Typography>
                            {getTimecardLink()}
                        </Stack>
                    )}
                </Stack>
            </Grid>
            {!compact && (
                <Grid item xs={5}>
                    <Stack gap={2}>
                        <Typography>{timecard.description}</Typography>
                        {getTimecardLink()}
                    </Stack>
                </Grid>
            )}
            <Grid item md={compact ? 4 : 2} xs={compact ? 6 : 2} alignSelf="center">
                <Stack direction="row" spacing={1} justifyContent="flex-end" alignItems="center">
                    {compact && (
                        <Typography variant="caption" pr={1}>
                            <HoursDisplay value={timecard?.timecardStats?.totalTimeInHours || 0} />
                        </Typography>
                    )}
                    <TimecardActionButtons timecard={timecard} />
                </Stack>
            </Grid>
        </Grid>
    );
};

export default TimecardListItem;
