import { MessageDetailsDto, ChatMessageCreateDto, ObjectType } from 'types/chat';
import { portalAPI } from './portalAPI';
import { filteredAndSortedQuery } from './util/filteredAndSortedQuery';
import { AutomatedNotification, NotificationCategory } from 'types/notifications';
import { BaseQueryFn, FetchArgs, FetchBaseQueryError, FetchBaseQueryMeta } from '@reduxjs/toolkit/query';
import { QueryLifecycleApi } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { APIInvoicesSlice } from './invoices';

const updateGetInvoiceById = (
    objectId: number,
    message: MessageDetailsDto[],
    api: QueryLifecycleApi<
        { objectType: ObjectType | null; objectId: number },
        BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, Record<string, unknown>, FetchBaseQueryMeta>,
        MessageDetailsDto[],
        'portalAPI'
    >
) => {
    api.dispatch(
        APIInvoicesSlice.util.updateQueryData('getInvoiceById', objectId, (invoice) =>
            Object.assign(invoice || {}, { chatMessageCount: message.length })
        )
    );
};

export const APINotificationsSlice = portalAPI
    .enhanceEndpoints({ addTagTypes: ['Notifications', 'Messages', 'AddressedMessages'] })
    .injectEndpoints({
        endpoints: (builder) => ({
            getNotificationsList: filteredAndSortedQuery<AutomatedNotification>(builder, '/v1/notifications', {
                providesTags: ['Notifications'],
            }),
            readNotification: builder.mutation<AutomatedNotification, { id: string | number }>({
                query: ({ id }) => ({
                    url: `/v1/notifications/read/${id}`,
                    method: 'PUT',
                }),
                invalidatesTags: (_1, _2, arg) =>
                    arg.id
                        ? [
                              'Notifications',
                              { type: 'Notifications', id: arg.id },
                              'AddressedMessages',
                              { type: 'AddressedMessages', id: arg.id },
                          ]
                        : ['Notifications', 'AddressedMessages'],
            }),
            readNotifications: builder.mutation<AutomatedNotification[], { type: NotificationCategory }>({
                query: ({ type }) => ({
                    url: `/v1/notifications/read/all/${type}`,
                    method: 'PUT',
                }),
                invalidatesTags: ['Notifications'],
            }),
            readMessages: builder.mutation<AutomatedNotification[], { type: string; idList: number[] }>({
                query: ({ ...body }) => ({
                    url: `/v1/notifications/read/messages`,
                    method: 'PUT',
                    body,
                }),
                invalidatesTags: ['AddressedMessages'],
            }),
            archiveNotification: builder.mutation<AutomatedNotification, { id: string | number }>({
                query: ({ id }) => ({
                    url: `/v1/notifications/archive/${id}`,
                    method: 'PUT',
                }),
                invalidatesTags: ['Notifications'],
            }),
            archiveNotifications: builder.mutation<AutomatedNotification[], { type: NotificationCategory }>({
                query: ({ type }) => ({
                    url: `/v1/notifications/archive/all/${type}`,
                    method: 'PUT',
                }),
                invalidatesTags: ['Notifications'],
            }),
            archiveMessages: builder.mutation<AutomatedNotification[], { type: string; idList: number[] }>({
                query: ({ ...body }) => ({
                    url: `/v1/notifications/archive/messages`,
                    method: 'PUT',
                    body,
                }),
                invalidatesTags: ['AddressedMessages'],
            }),
            getMessagesByTypeAndId: builder.query<
                MessageDetailsDto[],
                { objectType: ObjectType | null; objectId: number }
            >({
                query: ({ objectType, objectId }) =>
                    `/v1/notifications/messages?objectType=${objectType}&objectId=${objectId}`,
                keepUnusedDataFor: 0,
                async onQueryStarted({ objectId, objectType }, api) {
                    try {
                        const { data: messages } = await api.queryFulfilled;
                        if (objectType === ObjectType.INVOICE) {
                            updateGetInvoiceById(objectId, messages, api);
                        }
                        // eslint-disable-next-line no-empty
                    } catch {}
                },
                providesTags: ['Messages'],
            }),
            getMessagesAddressedToEmployee: builder.query<MessageDetailsDto[], { employeeId: number; search: string }>({
                query: ({ employeeId, search }) =>
                    `/v1/notifications/messages?objectType=ADDRESSED_TO_EMPLOYEE&objectId=${employeeId}&searchText=${search}`,
                keepUnusedDataFor: 0,
                providesTags: ['AddressedMessages'],
            }),
            addMessage: builder.mutation<MessageDetailsDto, ChatMessageCreateDto>({
                query: ({ ...body }) => ({
                    url: `/v1/notifications/message`,
                    method: 'POST',
                    body,
                }),
                invalidatesTags: ['Messages', 'AddressedMessages'],
            }),
        }),
        overrideExisting: false,
    });

export const {
    useGetNotificationsListQuery,
    useReadNotificationMutation,
    useReadNotificationsMutation,
    useReadMessagesMutation,
    useArchiveNotificationMutation,
    useArchiveNotificationsMutation,
    useArchiveMessagesMutation,
    useGetMessagesByTypeAndIdQuery,
    useGetMessagesAddressedToEmployeeQuery,
    useLazyGetMessagesAddressedToEmployeeQuery,
    useAddMessageMutation,
} = APINotificationsSlice;
