// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { BoxPlotOutlined, SettingFilled, StopOutlined, TeamOutlined, UserOutlined } from '@ant-design/icons';
import {
    Article as ArticleIcon,
    AssuredWorkload,
    Balance as BalanceIcon,
    BusinessOutlined as BusinessOutlinedIcon,
    Home as HomeIcon,
    MonetizationOn as MonetizationOnIcon,
    Percent,
    ReceiptLong as ReceiptLongIcon,
    WorkOutlineOutlined,
    WorkRounded,
} from '@mui/icons-material';

// type
import TimecardNavIcon from 'pages/timecards/TimecardNavIcon';
import { FeatureFlag } from 'types/features';
import { NavItemType } from 'types/menu';

// icons
const icons = {
    UserOutlined,
    BusinessOutlinedIcon,
    StopOutlined,
    AssuredWorkload,
    TeamOutlined,
    ArticleIcon,
    WorkOutlineOutlined,
    Percent,
    BalanceIcon,
    BoxPlotOutlined,
    SettingFilled,
    ReceiptLongIcon,
    MonetizationOnIcon,
    HomeIcon,
    WorkRounded,
};

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const getItems = (userId?: string): NavItemType => {
    return {
        id: 'other',
        type: 'group',
        children: [
            {
                id: 'dashboard',
                title: <FormattedMessage id="menu.dashboard" />,
                icon: icons.HomeIcon,
                type: 'item',
                url: '/home',
            },
            {
                id: 'contact-database',
                title: <FormattedMessage id="menu.contacts" />,
                icon: icons.TeamOutlined,
                type: 'collapse',
                children: [
                    {
                        id: 'contacts',
                        title: <FormattedMessage id="contacts.contacts" />,
                        type: 'item',
                        url: '/contacts',
                        altActiveUrls: ['/contact'],
                        disabled: false,
                    },
                    {
                        id: 'groups',
                        title: <FormattedMessage id="menu.groups" />,
                        type: 'item',
                        url: userId
                            ? `/groups?${new URLSearchParams(`qf=employees.employee.id=in=(${userId})`).toString()}`
                            : '/groups',
                        altActiveUrls: ['/group'],
                        disabled: false,
                    },
                    {
                        id: 'coi-checks',
                        title: <FormattedMessage id="menu.coi-check" />,
                        type: 'item',
                        url: '/coi-checks',
                        requiredFeature: FeatureFlag.COI_CHECK_FLOW_ENABLED,
                    },
                    {
                        id: 'contracts',
                        title: <FormattedMessage id="contracts.contracts" />,
                        type: 'item',
                        url: '/contracts',
                        altActiveUrls: ['/contract'],
                        // icon: icons.ArticleIcon,
                        disabled: false,
                    },
                ],
            },
            {
                id: 'cases',
                title: <FormattedMessage id="matters.matters" />,
                type: 'item',
                url: '/matters',
                altActiveUrls: ['/matter'],
                icon: icons.BalanceIcon,
                disabled: false,
            },
            {
                id: 'timecards',
                title: <FormattedMessage id="timecards.timecards" />,
                type: 'item',
                url: userId
                    ? `/timecards?${new URLSearchParams(`qf=employee.id=in=(${userId})`).toString()}`
                    : '/timecards',
                // altActiveUrls: ['/timecards'],
                icon: TimecardNavIcon,
            },
            {
                id: 'colleagues',
                title: <FormattedMessage id="menu.colleagues" />,
                type: 'item',
                url: `/employees?${new URLSearchParams(`qf=isActive=in=(true)`).toString()}`,
                altActiveUrls: ['/employee'],
                icon: icons.UserOutlined,
                disabled: false,
            },
            {
                id: 'bd',
                title: <FormattedMessage id="menu.bd" />,
                icon: icons.WorkRounded,
                type: 'collapse',
                children: [
                    {
                        id: 'efforts',
                        title: <FormattedMessage id="menu.efforts" />,
                        type: 'item',
                        url: userId
                            ? `/efforts?${new URLSearchParams(`qf=effortByEmployee.id=in=(${userId})`).toString()}`
                            : '/efforts',
                    },
                ],
            },
            {
                id: 'revenue-management-collapse',
                title: <FormattedMessage id="menu.revenue" />,
                type: 'collapse',
                icon: icons.MonetizationOnIcon,
                children: [
                    {
                        id: 'billableclients',
                        title: <FormattedMessage id="menu.billable-clients" />,
                        type: 'item',
                        url: '/billable-clients?qf=matterStats=isbillable=true',
                    },
                    {
                        id: 'invoices',
                        title: <FormattedMessage id="menu.invoices" />,
                        type: 'item',
                        url: '/invoices',
                    },
                    {
                        id: 'pricelists',
                        title: <FormattedMessage id="menu.pricelists" />,
                        type: 'item',
                        url: '/pricelists',
                        // icon: icons.ReceiptLongIcon,
                    },
                ],
            },
            {
                id: 'admin',
                title: <FormattedMessage id="menu.admin" />,
                type: 'collapse',
                icon: icons.SettingFilled,
                children: [
                    {
                        id: 'offices',
                        title: <FormattedMessage id="offices.offices" />,
                        type: 'item',
                        url: '/offices',
                        altActiveUrls: ['/office'],
                        // icon: icons.BusinessOutlinedIcon,
                    },
                    {
                        id: 'practice-areas',
                        title: <FormattedMessage id="menu.practice-areas" />,
                        type: 'item',
                        url: '/practice-areas',
                        altActiveUrls: ['/practice-area'],
                        // icon: icons.AssuredWorkload,
                    },
                    {
                        id: 'job-roles',
                        title: <FormattedMessage id="menu.job-roles" />,
                        type: 'item',
                        url: '/job-roles',
                        altActiveUrls: ['/job-role'],
                    },
                    {
                        id: 'job-positions',
                        title: <FormattedMessage id="menu.job-positions" />,
                        type: 'item',
                        url: '/job-positions',
                        altActiveUrls: ['/job-position'],
                    },
                    {
                        id: 'teams',
                        title: <FormattedMessage id="teams.teams" />,
                        type: 'item',
                        url: '/teams',
                        altActiveUrls: ['/team'],
                        // icon: icons.TeamOutlined,
                    },
                    {
                        id: 'roles-matrix',
                        title: <FormattedMessage id="menu.roles-matrix" />,
                        type: 'item',
                        url: '/roles-matrix',
                    },
                    {
                        id: 'classifiers',
                        title: <FormattedMessage id="menu.classifiers" />,
                        type: 'item',
                        url: '/classifiers',
                    },
                    {
                        id: 'financial-conditions',
                        title: <FormattedMessage id="menu.financial-conditions" />,
                        type: 'item',
                        url: '/financial-conditions',
                        // icon: icons.Percent,
                    },
                ],
            },
        ],
    };
};

export default getItems;
