import { Stack, Theme, Typography } from '@mui/material';
import Loader from '../../../../../../../components/Loader';
import { useGetTaskByIdQuery, useSwitchTasksStatusMutation } from 'store/api/tasks';
import { getClassifierText, t } from 'utils/getTranslations';
import { useClassifiers } from 'utils/useClassifiers';
import useAlert from 'hooks/useAlert';
import AddressedTaskCreatedInfo from './AddressedTaskCreatedInfo';
import AddressedTaskProperties from './AddressedTaskProperties';
import AddressedTaskUrgencyInfo from './AddressedTaskUrgencyInfo';
import AddressedTaskFooter from './AddressedTaskFooter';
import AddressedTaskHeader from './AddressedTaskHeader';
import { useNavigateWithReturnState } from '../../../../../../../utils/useNavigateWithReturnState';

interface Props {
    addressedTaskId: number | undefined;
    openTaskContext: () => void;
    toggleDrawer: () => void;
    theme: Theme;
}

const AddressedTaskDetailsView: React.FC<Props> = ({ addressedTaskId, openTaskContext, toggleDrawer, theme }) => {
    const { errorAlert } = useAlert();
    const navigate = useNavigateWithReturnState();
    const { data: taskData, isLoading: isTaskLoading } = useGetTaskByIdQuery(`${addressedTaskId}`, {
        skip: addressedTaskId === undefined,
    });
    const [switchTaskStatus] = useSwitchTasksStatusMutation();
    const { classifiers, isLoading: isLoadingClassifiers } = useClassifiers({
        codes: ['TASK_PRIORITY_TYPE', 'TASK_TYPE'],
    });

    const assignedToProperty = taskData?.assignedToEmployees ?? [];
    const getTaskTypeProperty =
        taskData?.taskTypeCodes?.map((code) => getClassifierText(classifiers['TASK_TYPE'], code)) ?? [];
    const getCasesProperty = taskData?.matters ?? [];
    const getClientsProperty = taskData?.clientParty
        ? [{ id: taskData?.clientParty?.id, name: taskData?.clientParty?.name }]
        : [];

    const handleSwitchTaskStatus = async () => {
        try {
            if (taskData?.id) {
                await switchTaskStatus({ id: `${taskData.id}` });
            }
        } catch (e) {
            console.error('onSubmit error: ', e);
            errorAlert(t('forms.error-submitting-the-form'));
        }
    };

    if (isTaskLoading || isLoadingClassifiers) {
        return <Loader />;
    }
    return taskData ? (
        <>
            <Stack
                sx={{
                    px: 2,
                    py: 1,
                    pb: '10px',
                    gap: 2,
                    width: '100%',
                    boxShadow: 'inset 0 -6px 6px rgba(0, 0, 0, 0.05)',
                    overflow: 'auto',
                }}
                direction="column"
                component="div"
            >
                <AddressedTaskHeader openTaskContext={openTaskContext} toggleDrawer={toggleDrawer} />

                <Stack
                    direction="column"
                    component="div"
                    sx={{ width: '100%', borderBottom: `1px solid ${theme.palette.divider}`, gap: 2, pb: '8px' }}
                >
                    <Typography variant="h4">{taskData?.name}</Typography>
                    <Typography>{taskData?.description}</Typography>
                </Stack>

                <AddressedTaskUrgencyInfo taskData={taskData} classifiers={classifiers} theme={theme} />

                <AddressedTaskProperties
                    labelId="tasks.view-form.assigned-to"
                    properties={assignedToProperty}
                    direction="column"
                    theme={theme}
                    onClick={(id) => navigate(`/employee/${id}`)}
                />

                <AddressedTaskProperties
                    labelId="tasks.view-form.type"
                    properties={getTaskTypeProperty}
                    direction="row"
                    useStatusChip={true}
                    theme={theme}
                />

                <AddressedTaskProperties
                    labelId="tasks.view-form.clients"
                    properties={getClientsProperty}
                    direction="column"
                    theme={theme}
                    onClick={(id) => navigate(`/contact/${id}`)}
                />

                <AddressedTaskProperties
                    labelId="tasks.view-form.cases"
                    properties={getCasesProperty}
                    direction="column"
                    theme={theme}
                    onClick={(id) => navigate(`/matter/${id}`)}
                />

                <AddressedTaskCreatedInfo createdAt={taskData?.createdAt} />
            </Stack>
            <AddressedTaskFooter
                status={taskData?.taskStatusTypeCode}
                taskId={taskData.id}
                handleSwitchTaskStatus={handleSwitchTaskStatus}
                toggleDrawer={toggleDrawer}
            />
        </>
    ) : (
        <></>
    );
};

export default AddressedTaskDetailsView;
