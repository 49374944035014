import React, { createContext, useEffect, useReducer, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// third-party
import {
    AuthenticationDetails,
    CognitoUser,
    CognitoUserAttribute,
    CognitoUserPool,
    CognitoUserSession,
} from 'amazon-cognito-identity-js';

// action - state management
import { LOGIN, LOGOUT } from 'store/reducers/actions';
import authReducer from 'store/reducers/auth';
import { logout as userLogout, setActiveTenant } from 'store/reducers/user';

// project imports
import { cognitoUser } from 'utils/cognitoUser';
import Loader from 'components/Loader';
import { AWS_API } from 'config';
import { AWSCognitoContextType, InitialLoginContextProps } from 'types/auth';

// Utils
import { removeLocalStorage, setLocalStorage } from 'utils/localStorage';

// Store
import { useDispatch } from 'store';
import useAlert from '../hooks/useAlert';

const initialState: InitialLoginContextProps = {
    isLoggedIn: false,
    isInitialized: false,
    user: null,
};

export const userPool = new CognitoUserPool({
    UserPoolId: AWS_API.poolId || '',
    ClientId: AWS_API.appClientId || '',
});

export const setSession = (serviceToken?: string | null) => {
    if (serviceToken) {
        setLocalStorage('serviceToken', serviceToken);
    } else {
        removeLocalStorage('serviceToken');
    }
};

// ==============================|| AWS Cognito CONTEXT & PROVIDER ||============================== //

const AWSCognitoContext = createContext<AWSCognitoContextType | null>(null);

export const AWSCognitoProvider = ({ children }: { children: React.ReactElement }): JSX.Element => {
    const [state, dispatch] = useReducer(authReducer, initialState);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const { errorAlert } = useAlert();

    const reduxDispatch = useDispatch();

    useEffect(() => {
        const init = async () => {
            try {
                const cognitoSession = await cognitoUser.getSession();
                if (!cognitoSession) {
                    dispatch({
                        type: LOGOUT,
                    });
                } else {
                    if (!cognitoSession?.isValid()) {
                        // console.log('Session is not valid anymore, fetching new token');
                        await new Promise((resolve, reject) => {
                            cognitoUser
                                .getCognitoUser()
                                ?.refreshSession(cognitoSession.getRefreshToken(), (err, session) => {
                                    if (err) {
                                        console.error('Error refreshing the user token: ', err);
                                        reject(err);
                                    }

                                    // console.log('Session: ', session);
                                    cognitoUser.setCognitoUserSession(session);
                                    resolve(session);
                                });
                        });
                    }
                    dispatch({
                        type: LOGIN,
                        payload: {
                            isLoggedIn: true,
                        },
                    });
                }
            } catch (err) {
                console.error(err);
                dispatch({
                    type: LOGOUT,
                });
            }
        };

        init();
    }, []);

    const login = async (email: string, password: string): Promise<void> => {
        // Cognito User instance to use for the authentication
        const cognitoLogin = new CognitoUser({
            Username: email,
            Pool: userPool,
        });

        const authData = new AuthenticationDetails({
            Username: email,
            Password: password,
        });

        try {
            await new Promise<boolean>((resolve, reject) => {
                cognitoLogin.authenticateUser(authData, {
                    onSuccess: (session: CognitoUserSession) => {
                        cognitoLogin.getUserData((err, cognitoData) => {
                            if (err) {
                                console.error('User authentication error: ', err);
                                reject(err);
                            }

                            if (cognitoData) {
                                setAndFetchUserData(cognitoLogin, session, email)
                                    .then((res) => resolve(res))
                                    .catch((err) => reject(err));
                            }
                        });
                    },
                    onFailure: (_err) => {
                        console.log('Login error: ', _err);
                        // error = _err;
                        reject(_err);
                    },
                    totpRequired: () => {
                        cognitoUser.setCognitoUser(cognitoLogin);
                        navigate('/mfa-verification');
                    },
                    newPasswordRequired: (userAttributes, requiredAttributes) => {
                        console.log('New password required');
                        console.log('userAttributes: ', userAttributes);
                        console.log('requiredAttributes: ', requiredAttributes);
                        navigate('/set-password');
                        // // User was signed up by an admin and must provide new
                        // // password and required attributes, if any, to complete
                        // // authentication.
                        // // the api doesn't accept this field back
                        // delete userAttributes.email_verified;
                        // // unsure about this field, but I don't send this back
                        // delete userAttributes.phone_number_verified;
                        // // Get these details and call
                        // usr.completeNewPasswordChallenge(password, userAttributes, requiredAttributes);
                    },
                });
            });
        } catch (e) {
            console.error('Authentication error: ', e);
            if (e instanceof Error) {
                throw e;
            }
        }
    };

    const azureLogin = async (email: string, jwt: string): Promise<void> => {
        if (!email || !jwt) {
            console.error('Missing email or JWT for Azure SSO login');
            throw new Error('Azure SSO login error');
        }

        // Cognito User instance to use for the authentication
        const cognitoLogin = new CognitoUser({
            Username: email,
            Pool: userPool,
        });

        // Custom object to identify the authentication flow in the Cognito Lambda trigger
        const clientMetadata = {
            authFlow: 'AZURE_SSO',
        };

        const authData = new AuthenticationDetails({
            Username: email,
            Password: '',
            ClientMetadata: clientMetadata,
        });

        try {
            setIsLoading(true);
            cognitoLogin.setAuthenticationFlowType('CUSTOM_AUTH');

            await new Promise<boolean>((resolve, reject) => {
                cognitoLogin.initiateAuth(authData, {
                    onSuccess: (session: CognitoUserSession) => {
                        cognitoLogin.getUserData((err, cognitoData) => {
                            if (err) {
                                console.error('User authentication error: ', err);
                                reject(err);
                            }

                            if (cognitoData) {
                                setAndFetchUserData(cognitoLogin, session, email)
                                    .then((res) => resolve(res))
                                    .catch((err) => reject(err));
                            }
                        });
                    },
                    totpRequired: () => {
                        cognitoUser.setCognitoUser(cognitoLogin);
                        navigate('/mfa-verification');
                    },
                    onFailure: (_err) => {
                        console.log('Login error: ', _err);
                        reject(_err);
                    },
                    customChallenge: async function (challengeParameters) {
                        // User authentication depends on challenge response
                        // send credentials to Cognito VerifyAuthChallenge lambda trigger for verification
                        cognitoLogin.sendCustomChallengeAnswer(jwt, this, clientMetadata);
                    },
                });
            });
        } catch (e) {
            console.error('Authentication error: ', e);
            if (e instanceof Error) {
                throw e;
            } else {
                throw new Error('Authentication error');
            }
        } finally {
            setIsLoading(false);
        }
    };

    // This function is not used in the app
    // User registration is started from the portal when adding new employee
    const register = (email: string, password: string, firstName: string, lastName: string) =>
        new Promise((success, rej) => {
            userPool.signUp(
                email,
                password,
                [
                    new CognitoUserAttribute({ Name: 'email', Value: email }),
                    new CognitoUserAttribute({ Name: 'name', Value: `${firstName} ${lastName}` }),
                ],
                [],
                async (err, result) => {
                    if (err) {
                        rej(err);
                        return;
                    }
                    success(result);
                }
            );
        });

    const logout = () => {
        const loggedInUser = userPool.getCurrentUser();
        if (loggedInUser) {
            setSession(null);
            loggedInUser.signOut();
            dispatch({ type: LOGOUT });
            reduxDispatch(userLogout());
        }
        removeLocalStorage('cognito_user');
    };

    const setInitialPassword = async (email: string, tempPassword: string, newPassword: string) => {
        const cognitoLogin = new CognitoUser({
            Username: email,
            Pool: userPool,
        });

        const authenticationDetails = new AuthenticationDetails({
            Username: email,
            Password: tempPassword,
        });

        let sessionUserAttributes: any;

        await new Promise((resolve, reject) => {
            cognitoLogin.authenticateUser(authenticationDetails, {
                onSuccess: function (result) {
                    // User authentication was successful
                    // resolve(result);
                    console.log('User authenticated successfully: ', result);
                    resolve(result);
                },

                onFailure: function (err) {
                    // User authentication was not successful
                    console.error('User authentication error: ', err);
                    reject(err);
                },

                totpRequired: function () {
                    cognitoUser.setCognitoUser(cognitoLogin);
                    navigate('/mfa-verification');
                },

                newPasswordRequired: function (userAttributes) {
                    // User was signed up by an admin and must provide new
                    // password and required attributes, if any, to complete
                    // authentication.

                    // the api doesn't accept this field back
                    delete userAttributes.email_verified;
                    // The API doesn't allow to change the email here
                    delete userAttributes.email;

                    // store userAttributes on global variable
                    sessionUserAttributes = userAttributes;
                    console.log('New password required');
                    resolve(true);
                },
            });
        });

        try {
            await new Promise((resolve, reject) => {
                // ... handle new password flow on your app
                console.log('Complete new password challenge');
                cognitoLogin.completeNewPasswordChallenge(newPassword, sessionUserAttributes, {
                    onSuccess: function (session) {
                        // console.log('New password challenge success: ', session);
                        resolve(session);
                    },
                    onFailure: function (err) {
                        console.error('completeNewPasswordChallenge error: ', err);
                        reject(err);
                    },
                });
            });
        } catch (error) {
            console.error('Handle new password error: ', error);
            throw error;
        }
    };

    const changePassword = async (email: string, oldPassword: string, newPassword: string) => {
        const cognitoUser = new CognitoUser({
            Username: email,
            Pool: userPool,
        });

        await new Promise((resolve, reject) => {
            cognitoUser.changePassword(oldPassword, newPassword, function (err, result) {
                if (err) {
                    console.error('Change password error: ', err);
                    reject(err);
                }
                console.log('call result: ' + result);
                resolve(result);
            });
        });
    };

    // Send the verification code to the user email
    const sendResetPasswordCode = async (email: string) => {
        const user = new CognitoUser({
            Username: email,
            Pool: userPool,
        });

        try {
            await new Promise((resolve, reject) => {
                user.forgotPassword({
                    onSuccess: function (data) {
                        // successfully initiated reset password request
                        console.log('CodeDeliveryData from forgotPassword: ', data);
                        resolve(data);
                    },
                    onFailure: function (err) {
                        console.error('Password reset error: ', err);
                        reject(err);
                    },
                });
            });
        } catch (e) {
            if (e instanceof Error) {
                throw e;
            }
        }
    };

    const resetPassword = async (email: string, verificationCode: string, newPassword: string) => {
        const user = new CognitoUser({
            Username: email,
            Pool: userPool,
        });

        try {
            const res: string = await new Promise((resolve, reject) => {
                user.confirmPassword(verificationCode, newPassword, {
                    onSuccess: function (data) {
                        // successfully changed password
                        resolve(data);
                    },
                    onFailure: function (err) {
                        console.error('Password reset error: ', err);
                        reject(err);
                    },
                });
            });

            return res || '';
        } catch (e) {
            if (e instanceof Error) {
                throw e;
            } else {
                console.error('Unknown error occurred in resetPassword: ', e);
                return ''; // return a default value
            }
        }
    };

    const setAndFetchUserData = async (cognitoLogin: CognitoUser, session: CognitoUserSession, username: string) => {
        return new Promise<boolean>((resolve, reject) => {
            // Global Cognito User instance for the app
            cognitoUser.setCognitoUser(cognitoLogin);
            cognitoUser.setCognitoUserSession(session);

            // Ask the user data from the DB
            fetch(import.meta.env.VITE_API_URL + '/v1/user', {
                headers: {
                    Authorization: `Bearer ${session.getIdToken().getJwtToken()}`,
                },
            })
                .then((res) => {
                    if (res.ok) {
                        return res.json(); // Proceed with parsing the JSON response
                    } else {
                        throw new Error('Incorrect username or password');
                    }
                })
                .then((user) => {
                    // Store the cognito_user value to instantiate the CognitoUser object after the page refresh
                    setLocalStorage('cognito_user', cognitoLogin.getUsername() || '');

                    // Store the user to the context
                    if (user) {
                        // reduxDispatch(setUser(user));
                        if (user.tenants?.length > 0 && user.tenants[0].id) {
                            reduxDispatch(setActiveTenant(user.tenants[0].id));
                        }
                    }
                    dispatch({
                        type: LOGIN,
                        payload: {
                            isLoggedIn: true,
                            user: {
                                email: username,
                            },
                        },
                    });

                    resolve(true);
                })
                .catch((err) => {
                    console.error('User authentication error: ', err);
                    errorAlert(err.message);
                    reject(err);
                });
        });
    };

    const setupTOTP = async (): Promise<string> => {
        const _cognitoUser = cognitoUser.getCognitoUser();
        if (!_cognitoUser) {
            throw new Error('User is not logged in, cannot setup MFA');
        }
        return new Promise((resolve, reject) => {
            _cognitoUser.associateSoftwareToken({
                associateSecretCode: (secretCode) => {
                    resolve(secretCode);
                },
                onFailure: (err) => {
                    reject(err);
                },
            });
        });
    };

    const verifyTOTP = async (code: string): Promise<void> => {
        const _cognitoUser = cognitoUser.getCognitoUser();
        if (!_cognitoUser) {
            throw new Error('Invalid state, user should be initialized');
        }
        return new Promise((resolve, reject) => {
            _cognitoUser.verifySoftwareToken(code, 'My TOTP device', {
                onSuccess: (session: CognitoUserSession) => {
                    _cognitoUser.setUserMfaPreference(
                        null,
                        {
                            Enabled: true,
                            PreferredMfa: true,
                        },
                        (err, result) => {
                            if (err) {
                                console.error('Error setting TOTP as preferred MFA:', err);
                                reject(err);
                            } else {
                                resolve();
                            }
                        }
                    );
                },
                onFailure: (err) => {
                    reject(err);
                },
            });
        });
    };

    const sendOTP = async (code: string): Promise<void> => {
        const _cognitoUser = cognitoUser.getCognitoUser();
        if (!_cognitoUser) {
            throw new Error('Invalid state, user should be initialized');
        }

        return new Promise((resolve, reject) => {
            _cognitoUser.sendMFACode(
                code,
                {
                    onSuccess: (session: CognitoUserSession) => {
                        _cognitoUser.getUserData((err, cognitoData) => {
                            if (err) {
                                console.error('User authentication error: ', err);
                                reject(err);
                            }

                            if (cognitoData) {
                                _cognitoUser.getUserAttributes((err, result) => {
                                    if (err) {
                                        console.error(err);
                                        reject('Could not get user attributes to get user email');
                                    }
                                    if (result) {
                                        const email = result
                                            .filter((attribute) => {
                                                return attribute.Name === 'email';
                                            })[0]
                                            .getValue();
                                        setAndFetchUserData(_cognitoUser, session, email)
                                            .then((res) => resolve())
                                            .catch((err) => reject(err));
                                    }
                                });
                            }
                        });
                    },
                    onFailure: (err) => {
                        reject(err);
                    },
                },
                'SOFTWARE_TOKEN_MFA'
            );
        });
    };

    const updateProfile = () => {};

    if ((state.isInitialized !== undefined && !state.isInitialized) || isLoading) {
        return <Loader />;
    }

    return (
        <AWSCognitoContext.Provider
            value={{
                ...state,
                login,
                azureLogin,
                logout,
                register,
                sendResetPasswordCode,
                resetPassword,
                updateProfile,
                setInitialPassword,
                setupTOTP,
                verifyTOTP,
                sendOTP,
            }}
        >
            {children}
        </AWSCognitoContext.Provider>
    );
};

export default AWSCognitoContext;
