import { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

// project import
import useAuth from 'hooks/useAuth';
import useAlert from 'hooks/useAlert';
import { cognitoUser } from 'utils/cognitoUser';

// Store
import { setActiveTenant, setUserData } from 'store/reducers/user';
import { useDispatch, useSelector } from 'store';
import { useGetUserDataQuery } from 'store/api/user';

// types
import { GuardProps } from 'types/auth';
import Loader from 'components/Loader';
import { useNavigateWithReturnState } from 'utils/useNavigateWithReturnState';

// ==============================|| AUTH GUARD ||============================== //

const AuthGuard = ({ children }: GuardProps): React.ReactElement | null => {
    const [loading, setLoading] = useState<boolean>(true);

    const { isLoggedIn } = useAuth();
    const navigateWithReturn = useNavigateWithReturnState();

    const dispatch = useDispatch();
    const { errorAlert } = useAlert();
    const { formatMessage } = useIntl();

    const { activeTenantId } = useSelector((state) => state.user);

    const { data: userData, isLoading: isUserDataLoading } = useGetUserDataQuery();

    // Load the user initial data (id, tenants etc.)
    const loadInitialData = useCallback(async () => {
        try {
            setLoading(true);

            const cognitoSession = await cognitoUser.getSession();

            if (!cognitoSession) {
                return;
            }

            if (!cognitoSession?.isValid()) {
                console.log('Session is not valid anymore, fetching new token');
            }
        } catch (e) {
            if (e instanceof Error) {
                console.error('Error updating the Cognito session: ', e);
                errorAlert(
                    formatMessage({ id: 'user.error-fetching-the-user-data-detailed' }, { message: e?.message })
                );
            } else {
                errorAlert(formatMessage({ id: 'user.error-fetching-the-user-data' }));
            }
        }
    }, [formatMessage, errorAlert]);

    useEffect(() => {
        if (!isLoggedIn) {
            navigateWithReturn('login', { replace: true });
        } else {
            loadInitialData();
        }
    }, [loadInitialData, isLoggedIn, navigateWithReturn]);

    if (isUserDataLoading) {
        return <Loader />;
    }

    if (userData && activeTenantId === null) {
        if (userData.tenants?.length > 0 && userData.tenants[0]) {
            dispatch(setActiveTenant(userData.tenants[0]));
        }
    }

    if (userData) {
        dispatch(setUserData({ ...userData }));
    }

    return children;
};

export default AuthGuard;
