import { Box, CardContent, Grid, Stack, useTheme } from '@mui/material';
import { useForm } from 'react-hook-form';
import SelectInput from 'components/forms/inputs/rhf-inputs/SelectInput';
import { ClassifierData } from 'types/classifiers';
import { getOptionsFromClassifier } from 'utils/formUtil';
import { ModalHeader } from '../ModalHeader';
import { ModalFooter } from '../ModalFooter';
import { t } from 'utils/getTranslations';
import { ExpenseCreateDto, ExpenseDto, ExpenseForm } from 'types/expenses';
import { MatterListItemMin } from 'types/matters';
import { NamedEntityMinDto } from 'types/other';
import DecimalInput from 'components/forms/inputs/rhf-inputs/DecimalInput';
import DateInput from 'components/forms/inputs/rhf-inputs/DateInput';
import TextAreaInput from 'components/forms/inputs/rhf-inputs/TextAreaInput';
import CheckboxInput from 'components/forms/inputs/rhf-inputs/CheckboxInput';
import { TenantDto } from '../../../types/tenants';

interface Props {
    titleId?: string;
    onChange?: (value: ExpenseCreateDto) => void;
    onChangeRaw?: (value: ExpenseForm) => void;
    onClose: () => void;
    initialData?: ExpenseForm;
    expense?: ExpenseDto;
    classifiers: Record<string, ClassifierData>;
    matterList: MatterListItemMin[] | undefined;
    employeeList: NamedEntityMinDto[] | undefined;
    disableSelectMatter?: boolean;
    hideSelectMatter?: boolean;
    tenant?: TenantDto;
    readOnly: boolean;
}

const defaultValues: ExpenseForm = {
    expenseType: null,
    currencyType: null,
    description: '',
    expenseAmount: '',
    expenseDate: '',
    isNonBillable: false,
    employee: null,
    matter: null,
    timecardId: null,
    expenseAmountOriginalCurrency: '',
    originalCurrencyType: null,
};

const ExpenseModal = (props: Props) => {
    const {
        titleId = 'expenses.edit',
        initialData,
        matterList,
        employeeList,
        classifiers,
        disableSelectMatter,
        hideSelectMatter,
        onClose,
        onChange,
        onChangeRaw,
        readOnly,
        tenant,
    } = props;
    const theme = useTheme();

    const { handleSubmit, control, setValue, getValues } = useForm<ExpenseForm>({
        defaultValues: defaultValues,
        values: initialData as ExpenseForm,
    });

    const handleOnChange = (data: ExpenseForm) => {
        if (onChange) onChange(mapFormDataToDto(data));
        if (onChangeRaw) onChangeRaw(data);
        onClose();
    };
    const onSave = async () => {
        await handleSubmit((data) => handleOnChange(data))();
    };

    return (
        <>
            <ModalHeader title={readOnly ? t('expenses.view-expense') : t(titleId)} onCancel={onClose} theme={theme} />
            <CardContent
                sx={{
                    padding: '38px 32px',
                    height: '100%',
                    overflowY: 'auto',
                    [theme.breakpoints.down('sm')]: {
                        padding: '24px 16px',
                    },
                }}
            >
                <Stack direction="column" spacing={2}>
                    {!hideSelectMatter ? (
                        <SelectInput
                            setValue={setValue}
                            control={control}
                            label="expenses.form.case-client"
                            required
                            name="matter"
                            options={matterList ?? []}
                            getOptionLabel="fullName"
                            getOptionIdentifier="id"
                            placeholder="expenses.form.case-client"
                            readonly={disableSelectMatter || readOnly}
                        />
                    ) : null}

                    <SelectInput
                        setValue={setValue}
                        control={control}
                        label="expenses.form.expense-type"
                        required
                        name="expenseType"
                        options={
                            (getOptionsFromClassifier(classifiers['EXPENSE_TYPE']) as {
                                label: string;
                                value: string;
                            }[]) ?? []
                        }
                        getOptionLabel="label"
                        getOptionIdentifier="value"
                        placeholder="expenses.form.expense-type"
                        readonly={readOnly}
                    />

                    <Box>
                        <Grid container spacing={2}>
                            <Grid item flex={1}>
                                <DecimalInput
                                    name="expenseAmount"
                                    control={control}
                                    label="expenses.form.amount"
                                    placeholder="expenses.form.amount"
                                    required
                                    readonly={readOnly}
                                />
                            </Grid>
                            <Grid item flex={1}>
                                <SelectInput
                                    setValue={setValue}
                                    control={control}
                                    label="expenses.form.currency"
                                    required
                                    disabled={true}
                                    name="currencyType"
                                    options={
                                        (getOptionsFromClassifier(classifiers['CURRENCY']).filter((option) => {
                                            const matter = matterList?.find(
                                                (matter) => matter.id === getValues('matter')?.id
                                            );
                                            if (hideSelectMatter) {
                                                return option.value === tenant?.baseCurrencyTypeCode;
                                            }
                                            return option.value === matter?.pricelistCurrencyTypeCode;
                                        }) as {
                                            label: string;
                                            value: string;
                                        }[]) ?? []
                                    }
                                    getOptionLabel="label"
                                    getOptionIdentifier="value"
                                    placeholder="expenses.form.currency"
                                    readonly={readOnly}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                    <Box>
                        <Grid container spacing={2}>
                            <Grid item flex={1}>
                                <DecimalInput
                                    name="expenseAmountOriginalCurrency"
                                    control={control}
                                    label="expenses.form.amount-original-currency"
                                    placeholder="expenses.form.amount-original-currency"
                                    readonly={readOnly}
                                />
                            </Grid>
                            <Grid item flex={1}>
                                <SelectInput
                                    setValue={setValue}
                                    control={control}
                                    label="expenses.form.currency"
                                    name="originalCurrencyType"
                                    options={
                                        (getOptionsFromClassifier(classifiers['CURRENCY']) as {
                                            label: string;
                                            value: string;
                                        }[]) ?? []
                                    }
                                    getOptionLabel="label"
                                    getOptionIdentifier="value"
                                    placeholder="expenses.form.currency"
                                    readonly={readOnly}
                                />
                            </Grid>
                        </Grid>
                    </Box>

                    <SelectInput
                        setValue={setValue}
                        control={control}
                        label="expenses.form.added-by"
                        required
                        name="employee"
                        options={employeeList ?? []}
                        getOptionLabel="name"
                        getOptionIdentifier="id"
                        placeholder="expenses.form.added-by"
                        readonly={readOnly}
                    />
                    <Box>
                        <Grid container spacing={2}>
                            <Grid item flex={1}>
                                <DateInput
                                    control={control}
                                    name="expenseDate"
                                    label="date"
                                    required
                                    readonly={readOnly}
                                    maxDate={new Date()}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                    <TextAreaInput
                        name="description"
                        control={control}
                        label="expenses.form.details-of-expense"
                        placeholder="expenses.form.details-of-expense"
                        readonly={readOnly}
                    />
                    <CheckboxInput
                        control={control}
                        name="isNonBillable"
                        label="expenses.form.is-non-billable"
                        readonly={readOnly}
                    />
                </Stack>
            </CardContent>
            <ModalFooter onCancel={onClose} onSave={onSave} readOnly={readOnly} theme={theme} />
        </>
    );
};

export default ExpenseModal;

const mapFormDataToDto = (expense: ExpenseForm): ExpenseCreateDto => ({
    expenseTypeCode: expense?.expenseType?.value ?? null,
    currencyTypeCode: expense?.currencyType?.value ?? null,
    description: expense?.description ?? '',
    expenseAmount: expense?.expenseAmount ?? '0',
    expenseDate: expense?.expenseDate ? new Date(expense.expenseDate) : null,
    isNonBillable:
        expense?.isNonBillable !== undefined && expense?.isNonBillable !== null ? expense.isNonBillable : false,
    employeeId: expense?.employee?.id ?? null,
    matterId: expense?.matter?.id ?? null,
    timecardId: expense?.timecardId ?? null,
    expenseAmountOriginalCurrency: expense?.expenseAmountOriginalCurrency ?? null,
    originalCurrencyTypeCode: expense?.originalCurrencyType?.value ?? null,
});
