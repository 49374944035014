import { Divider, Fab, Link, Menu, MenuItem, styled, Typography, useTheme } from '@mui/material';
import PlusIcon from 'assets/images/icons/PlusIcon.svg?react';
import { useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import LinkWithOrigin from 'components/util/LinkWithOrigin';
import { useIsFeatureEnabled } from '../../utils/useFeatureFlags';
import { FeatureFlag } from '../../types/features';
import { useHasPermissions } from 'utils/permissionUtil';

type MenuItemType = {
    label: string;
    url: string;
    isDisabled?: boolean;
};

const GlobalAddButton: React.FC = () => {
    const theme = useTheme();
    const { pathname } = useLocation();
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const shouldHideFAB = useMemo(() => Boolean(pathname?.match(/(\/(?:edit|new))$/)), [pathname]);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const isOpen = Boolean(anchorEl);
    const id = isOpen ? 'global-add-button-fab' : undefined;

    const hasPermissionCreateTimecards = useHasPermissions(['CREATE.TIMECARDS']);
    const hasPermissionCreateParties = useHasPermissions(['CREATE.PARTIES']);
    const hasPermissionCreateMatters = useHasPermissions(['CREATE.MATTERS']);
    const hasPermissionCreateContracts = useHasPermissions(['CREATE.CONTRACTS']);
    const hasPermissionCreateEfforts = useHasPermissions(['CREATE.EFFORTS']);
    const hasPermissionCreateTasks = useHasPermissions(['CREATE.TASKS']);
    const hasPermissionCreateCoiChecks = useHasPermissions(['CREATE.COI_CHECKS']);

    const isCoiCheckFlowFeatureEnabled = useIsFeatureEnabled(FeatureFlag.COI_CHECK_FLOW_ENABLED);

    const menuItems: MenuItemType[] = [
        {
            label: 'Timecard',
            url: '/timecards/new',
            isDisabled: !hasPermissionCreateTimecards,
        },
        {
            label: 'Contact',
            url: '/contact/new',
            isDisabled: !hasPermissionCreateParties,
        },
        {
            label: 'Case',
            url: '/matter/new',
            isDisabled: !hasPermissionCreateMatters,
        },
        {
            label: 'Contract',
            url: '/contract/new',
            isDisabled: !hasPermissionCreateContracts,
        },
        {
            label: 'BD effort',
            url: '/effort/new',
            isDisabled: !hasPermissionCreateEfforts,
        },
        {
            label: 'Task',
            url: '/task/new',
            isDisabled: !hasPermissionCreateTasks,
        },
    ];

    if (isCoiCheckFlowFeatureEnabled) {
        menuItems.push({
            label: 'COI check',
            url: '/coi-checks',
            isDisabled: !hasPermissionCreateCoiChecks,
        });
    }

    const hasActiveMenuItems = menuItems.some((item) => !item.isDisabled);

    const renderMenuItem = (item: MenuItemType): JSX.Element | null => {
        return item.isDisabled ? null : (
            <Link
                key={item.label}
                onClick={handleClose}
                color={theme.palette.text.primary}
                component={LinkWithOrigin}
                to={item.url}
                sx={{
                    '&:hover': {
                        color: 'inherit',
                        textDecoration: 'none',
                    },
                }}
            >
                <MenuItem>{item.label}</MenuItem>
            </Link>
        );
    };

    if (shouldHideFAB) {
        return null;
    }

    return hasActiveMenuItems ? (
        <>
            <FabStyled color="secondary" aria-describedby={id} aria-label="add" disableRipple onClick={handleClick}>
                <PlusIcon />
            </FabStyled>
            <Menu
                id={id}
                anchorEl={anchorEl}
                open={isOpen}
                onClose={handleClose}
                transformOrigin={{ horizontal: 'center', vertical: 'bottom' }}
                anchorOrigin={{
                    horizontal: 'right',
                    vertical: 'top',
                }}
                sx={{
                    top: '16px',
                    left: '-8px',
                    '.MuiPaper-root': {
                        minWidth: '216px',
                    },
                }}
            >
                <Typography variant="h6" sx={{ margin: '0 16px', fontWeight: 600 }}>
                    <FormattedMessage id="add-new" />
                </Typography>
                <Divider sx={{ margin: '8px 0' }} />
                {menuItems.map((item) => renderMenuItem(item))}
            </Menu>
        </>
    ) : null;
};

export default GlobalAddButton;

export const FabStyled = styled(Fab)(({ theme }) => ({
    position: 'fixed',
    width: 62,
    height: 62,
    bottom: 24,
    right: 24,
    transition: 'transform .25s, background-color .25s',
    '& svg': {
        transition: 'transform .25s',
    },
    '&:hover': {
        transform: 'scale(1.064516129032258)',
    },
    '&:hover svg': {
        transform: 'scale(0.939393939393939)',
    },
}));
