import { Theme, Button, CardActions, CSSObject, Box } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import ClearIcon from '@mui/icons-material/Clear';

interface Props {
    onSecondary?: () => void;
    onPrimary?: () => void;
    theme: Theme;
    applyColor?: 'warning' | 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info';
}

export const DrawerFooter = ({ onSecondary, onPrimary, theme, applyColor = 'success' }: Props): JSX.Element => {
    const style: CSSObject = {
        borderTop: `1px solid ${theme.palette.divider}`,
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        position: 'sticky',
        bottom: 0,
        padding: '16px 24px',
        backgroundColor: theme.palette.common.white,
    };

    if (!onSecondary && !onPrimary) {
        return <Box />;
    }

    return (
        <CardActions sx={style}>
            {onSecondary ? (
                <Button
                    color="primary"
                    size="small"
                    onClick={onSecondary}
                    sx={{
                        fontWeight: 400,
                        fontSize: '14px',
                        height: 'fit-content',
                        padding: '0px 4px',
                        '.MuiButton-startIcon': {
                            mr: '2px',
                            '> :nth-of-type(1)': {
                                fontSize: '13px',
                            },
                        },
                    }}
                    startIcon={<ClearIcon />}
                >
                    <FormattedMessage id="clearFilters" />
                </Button>
            ) : null}

            {onPrimary ? (
                <Button variant="contained" size="small" onClick={onPrimary} color={applyColor}>
                    <FormattedMessage id="apply" />
                </Button>
            ) : null}
        </CardActions>
    );
};
