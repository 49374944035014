import { SyntheticEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { Alert, Button, Fade, Grow, Slide, SlideProps } from '@mui/material';
import MuiSnackbar from '@mui/material/Snackbar';

// project-import
import IconButton from './IconButton';

// assets
import { CloseOutlined } from '@ant-design/icons';

import { KeyedObject, RootStateProps } from 'types/root';
import { closeSnackbar } from 'store/reducers/snackbar';

import PriorityHighRoundedIcon from '@mui/icons-material/PriorityHighRounded';
import DoneIcon from '@mui/icons-material/Done';

// animation function
function TransitionSlideLeft(props: SlideProps) {
    return <Slide {...props} direction="left" />;
}

function TransitionSlideUp(props: SlideProps) {
    return <Slide {...props} direction="up" />;
}

function TransitionSlideRight(props: SlideProps) {
    return <Slide {...props} direction="right" />;
}

function TransitionSlideDown(props: SlideProps) {
    return <Slide {...props} direction="down" />;
}

function GrowTransition(props: SlideProps) {
    return <Grow {...props} />;
}

// animation options
const animation: KeyedObject = {
    SlideLeft: TransitionSlideLeft,
    SlideUp: TransitionSlideUp,
    SlideRight: TransitionSlideRight,
    SlideDown: TransitionSlideDown,
    Grow: GrowTransition,
    Fade,
};

// ==============================|| SNACKBAR ||============================== //

const Snackbar = (): JSX.Element => {
    const dispatch = useDispatch();
    const snackbar = useSelector((state: RootStateProps) => state.snackbar);
    const { actionButton, anchorOrigin, alert, close, message, open, transition, variant, autoHideDuration } = snackbar;

    const handleClose = (event: SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        dispatch(closeSnackbar());
    };

    return (
        <>
            {/* default snackbar */}
            {variant === 'default' && (
                <MuiSnackbar
                    anchorOrigin={anchorOrigin}
                    open={open}
                    autoHideDuration={autoHideDuration}
                    onClose={handleClose}
                    message={message}
                    TransitionComponent={animation[transition]}
                    action={
                        <>
                            <Button color="secondary" size="small" onClick={handleClose}>
                                UNDO
                            </Button>
                            <IconButton
                                size="small"
                                aria-label="close"
                                color="inherit"
                                onClick={handleClose}
                                sx={{ mt: 0.25 }}
                            >
                                <CloseOutlined />
                            </IconButton>
                        </>
                    }
                />
            )}

            {/* alert snackbar */}
            {variant === 'alert' && (
                <MuiSnackbar
                    TransitionComponent={animation[transition]}
                    anchorOrigin={anchorOrigin}
                    open={open}
                    autoHideDuration={autoHideDuration}
                    onClose={handleClose}
                >
                    <Alert
                        variant={alert.variant}
                        severity={alert.severity}
                        sx={{ justifyContent: 'center' }}
                        iconMapping={{
                            error: <PriorityHighRoundedIcon color={alert.severity} />,
                            warning: <PriorityHighRoundedIcon color={alert.severity} />,
                            success: <DoneIcon color={alert.severity} />,
                        }}
                        action={
                            close ? (
                                <IconButton size="small" aria-label="close" variant="contained" onClick={handleClose}>
                                    <CloseOutlined />
                                </IconButton>
                            ) : null
                        }
                    >
                        {message}
                    </Alert>
                </MuiSnackbar>
            )}
        </>
    );
};

export default Snackbar;
