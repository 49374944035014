// material-ui
import { Theme } from '@mui/material/styles';

// ==============================|| OVERRIDES - MUI MENU ||============================== //

export default function Menu(theme: Theme) {
    return {
        MuiMenu: {
            styleOverrides: {
                root: {
                    '.MuiMenu-paper': {
                        borderRadius: 8,
                        boxShadow: '0px 4px 20px 0px rgba(0, 0, 0, 0.20)',
                        minWidth: 156,
                    },
                },
            },
        },
    };
}
