import { useCallback } from 'react';
import { NavigateOptions, To, useNavigate, useLocation } from 'react-router-dom';

export const useNavigateWithReturnState = (fallback?: To) => {
    const reactNavigate = useNavigate();

    const { pathname, search } = useLocation();

    const returnTo = fallback ? fallback : `${pathname + search}`;

    const navigate = useCallback((to: To, options: NavigateOptions = {}) => {
        reactNavigate(to, { ...options, state: { ...options?.state, returnTo } });
    }, []);

    return navigate;
};

export interface LocationParams<Data> {
    pathname: string;
    state: Data;
    search: string;
    hash: string;
    key: string;
}

export interface NavigateWithReturn {
    returnTo: To;
}

/**
 *
 * @param fallback May be either a URL or the pieces of a URL path.
 * @returns Return url from state or a fallback url if state is missing.
 */
export const useReturnToLocation = (fallback: To): To => {
    const { state } = useLocation() as LocationParams<NavigateWithReturn>;

    return state?.returnTo || fallback;
};
