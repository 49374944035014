import { store, useDispatch } from 'store';
import { snackbar, openSnackbar } from 'store/reducers/snackbar';

export interface AlertActions {
    successAlert: (message: string, autoHideDuration?: number) => void;
    errorAlert: (message: string) => void;
    warningAlert: (message: string) => void;
    infoAlert: (message: string) => void;
}

const useAlert = (): AlertActions => {
    const dispatch = useDispatch();

    const successAlert = (message: string, autoHideDuration?: number) => {
        dispatch(
            openSnackbar({
                open: true,
                message: message,
                variant: 'alert',
                alert: {
                    color: 'success',
                },
                close: true,
                autoHideDuration: autoHideDuration,
            })
        );
    };

    const errorAlert = (message: string) => {
        dispatch(
            openSnackbar({
                open: true,
                message: message,
                variant: 'alert',
                alert: {
                    color: 'error',
                },
                close: true,
            })
        );
    };

    const warningAlert = (message: string) => {
        dispatch(
            openSnackbar({
                open: true,
                message: message,
                variant: 'alert',
                alert: {
                    color: 'warning',
                },
                close: true,
            })
        );
    };

    const infoAlert = (message: string) => {
        dispatch(
            openSnackbar({
                open: true,
                message: message,
                variant: 'alert',
                alert: {
                    color: 'info',
                },
                close: true,
            })
        );
    };

    return { successAlert, errorAlert, warningAlert, infoAlert };
};

//  Add alert without a hook (to use inside other hooks)
export const addErrorMessage = (message: string): void => {
    try {
        store.dispatch(
            snackbar.actions.openSnackbar({
                open: true,
                message: message,
                variant: 'alert',
                alert: {
                    color: 'error',
                },
                close: true,
            })
        );
    } catch (e) {
        console.error('Error adding new errormessage to the store: ', e);
    }
};

export default useAlert;
