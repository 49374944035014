import { createSlice } from '@reduxjs/toolkit';

// types
import { SnackbarProps } from 'types/snackbar';

const initialState: SnackbarProps = {
    action: false,
    open: false,
    message: 'Note archived',
    anchorOrigin: {
        vertical: 'top',
        horizontal: 'center',
    },
    variant: 'default',
    alert: {
        variant: 'filled',
        severity: 'info',
    },
    transition: 'Fade',
    close: true,
    actionButton: false,
    autoHideDuration: 6000,
};

// ==============================|| SLICE - SNACKBAR ||============================== //

export const snackbar = createSlice({
    name: 'snackbar',
    initialState,
    reducers: {
        openSnackbar(state, action) {
            const { open, message, anchorOrigin, variant, alert, transition, close, actionButton, autoHideDuration } =
                action.payload;

            state.action = !state.action;
            state.open = open || initialState.open;
            state.message = message || initialState.message;
            state.anchorOrigin = anchorOrigin || initialState.anchorOrigin;
            state.variant = variant || initialState.variant;
            state.alert = {
                variant: alert?.variant || initialState.alert.variant,
                severity: alert?.color || initialState.alert.severity,
            };
            state.transition = transition || initialState.transition;
            state.close = close === false ? close : initialState.close;
            state.actionButton = actionButton || initialState.actionButton;
            state.autoHideDuration = autoHideDuration || initialState.autoHideDuration;
        },

        closeSnackbar(state) {
            state.open = false;
        },
    },
});

export default snackbar.reducer;

export const { closeSnackbar, openSnackbar } = snackbar.actions;
