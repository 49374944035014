import { useCallback } from 'react';
import { Theme, Box, Typography, IconButton, Button, Stack } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { useForm } from 'react-hook-form';
import CloseIcon from '@mui/icons-material/Close';
import ClearIcon from '@mui/icons-material/Clear';

import { dispatch } from 'store';
import { ChatForm, ChatMessageCreateDto, MessageDetailsDto as ChatMessageType, ObjectType } from 'types/chat';
import TextAreaInput from 'components/forms/inputs/rhf-inputs/TextAreaInput';
import SelectInput from 'components/forms/inputs/rhf-inputs/SelectInput';
import ChatMessage from './ChatMessage';
import { useGetEmployeesListQuery } from 'store/api/employees';
import { useAddMessageMutation } from 'store/api/notifications';
import { closeChat } from 'store/reducers/chat';
import { DraftFormType, useDraftForm } from 'hooks/useDraftForm';
import StatusChip from 'components/@extended/StatusChip';
import { t } from 'utils/getTranslations';

interface Props {
    objectId: number;
    objectType: ObjectType;
    messages: ChatMessageType[];
    theme: Theme;
    isLoading?: boolean;
}

const ChatContent: React.FC<Props> = ({ objectId, objectType, messages, theme, isLoading = false }) => {
    const [addMessage, { isLoading: isAddingMessage }] = useAddMessageMutation();

    const { handleSubmit, control, setValue, reset, formState } = useForm<ChatForm>({
        defaultValues: defaultValues,
    });

    const { isDraft } = useDraftForm({
        control,
        type: DraftFormType.CHAT,
        objectId,
        objectType,
        setValue,
        debounceMillis: 250,
    });

    const closeDrawer = useCallback(() => {
        dispatch(closeChat());
    }, []);

    const onSubmit = async (formData: ChatForm): Promise<void> => {
        addMessage(mapToChatMessage({ ...formData, objectType, objectId })).then(() => {
            reset();
        });
    };

    const handleSendMessage = () => {
        handleSubmit((data) => onSubmit(data))();
    };

    const { data: employees } = useGetEmployeesListQuery({});

    const isSendButtonDisabled = !formState.isValid || isLoading || isAddingMessage;

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                overflow: 'auto',
                width: '380px',
                [theme.breakpoints.down('sm')]: {
                    width: 'calc(100vw - 48px)',
                },
            }}
        >
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                gap={1}
                sx={{
                    padding: '16px 16px 16px 24px',
                    borderBottom: `1px solid ${theme.palette.divider}`,
                }}
            >
                <Stack direction="row" gap={1} flex="1">
                    <Typography sx={{ fontWeight: 600, fontSize: 16, lineHeight: '24px' }}>
                        <FormattedMessage id="chat.chat" />
                    </Typography>
                    {isDraft ? (
                        <Stack direction="row" flex="1" justifyContent="space-between" alignItems="center">
                            <StatusChip theme={theme} label={t('draft')} type="outlined" />
                            <Button
                                color="primary"
                                size="small"
                                variant="text"
                                onClick={() => {
                                    reset();
                                }}
                                sx={{
                                    fontWeight: 400,
                                    height: 'fit-content',
                                    padding: '0px 4px',
                                }}
                                startIcon={<ClearIcon />}
                            >
                                <FormattedMessage id="delete-draft" />
                            </Button>
                        </Stack>
                    ) : null}
                </Stack>
                <IconButton aria-label="close notifications" onClick={closeDrawer}>
                    <CloseIcon />
                </IconButton>
            </Stack>
            <Box
                sx={{
                    padding: '18px 0px 24px 24px',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '24px',
                    overflow: 'auto',
                }}
            >
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px', paddingRight: '24px' }}>
                    <TextAreaInput
                        control={control}
                        name="message"
                        required
                        placeholder="chat.question-answer"
                        maxRows={5}
                        minRows={5}
                        disabled={isLoading}
                    />
                    <SelectInput
                        setValue={setValue}
                        control={control}
                        name="employee"
                        required
                        options={employees ?? []}
                        getOptionLabel="name"
                        getOptionIdentifier="id"
                        placeholder="chat.to-employee"
                        noPreSelect
                        disabled={isLoading}
                    />
                    <Button
                        disabled={isSendButtonDisabled}
                        variant="contained"
                        color="secondary"
                        size="small"
                        sx={{
                            mt: 1,
                            width: 'min-content',
                        }}
                        type="submit"
                        onClick={handleSendMessage}
                    >
                        <FormattedMessage id="send" />
                    </Button>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px', overflow: 'auto' }}>
                    {!isLoading
                        ? messages.map((message) => <ChatMessage key={message.id} theme={theme} message={message} />)
                        : null}
                </Box>
            </Box>
        </Box>
    );
};

export const mapToChatMessage = (form: ChatForm): ChatMessageCreateDto => ({
    manualMessageText: form.message,
    employee: form.employee?.id || null,
    objectId: form.objectId,
    objectType: form.objectType,
});

const defaultValues: ChatForm = {
    message: '',
    employee: null,
    objectId: null,
    objectType: null,
};

export default ChatContent;
