// project import
import Routes from 'routes';
import ThemeCustomization from 'themes';
import Locales from 'components/Locales';
import ScrollTop from 'components/ScrollTop';
import Snackbar from 'components/@extended/Snackbar';

// auth provider
import { AWSCognitoProvider as AuthProvider } from 'contexts/AWSCognitoContext';

// Utils
import ModalProvider from 'components/modals/ModalProvider';

import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { Persistor } from 'redux-persist';
import { PropsWithChildren } from 'react';
import { store, persister } from 'store';
import { ConfigProvider } from 'contexts/ConfigContext';

// ==============================|| APP - THEME, ROUTER, LOCAL  ||============================== //

const MaybePersistGate = ({ children, persister }: PropsWithChildren<{ persister: Persistor }>): JSX.Element => {
    if (persister.getState().registry.length > 0) {
        return (
            <PersistGate loading={null} persistor={persister}>
                {children}
            </PersistGate>
        );
    }
    return <>{children}</>;
};

const App = (): React.ReactElement => (
    <ReduxProvider store={store}>
        <MaybePersistGate persister={persister}>
            <ConfigProvider>
                <ThemeCustomization>
                    <Locales>
                        <ScrollTop>
                            <AuthProvider>
                                <ModalProvider>
                                    <>
                                        <Routes />
                                        <Snackbar />
                                    </>
                                </ModalProvider>
                            </AuthProvider>
                        </ScrollTop>
                    </Locales>
                </ThemeCustomization>
            </ConfigProvider>
        </MaybePersistGate>
    </ReduxProvider>
);

export default App;
