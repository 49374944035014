import { BaseQueryFn, FetchArgs, FetchBaseQueryError, FetchBaseQueryMeta } from '@reduxjs/toolkit/dist/query';
import { EndpointBuilder, ResultDescription } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { PaginationProps, QueryResponse } from 'types/paginationAndFilter';
import { QueryParams } from '../timecards';
import { objectToQueryParams } from 'utils/linkUtil';

type Options<DataType, TagTypes extends string> = {
    providesTags?:
        | ResultDescription<
              TagTypes,
              QueryResponse<DataType>,
              QueryParams,
              FetchBaseQueryError,
              FetchBaseQueryMeta | undefined
          >
        | undefined;
} & PaginationProps;

export const accumulatedQueryWithFilters = <DataType>(
    builder: EndpointBuilder<
        BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, Record<string, unknown>, FetchBaseQueryMeta>,
        string,
        'portalAPI'
    >,
    endpoint: string,
    options: Options<DataType, string> = {}
) =>
    builder.query<QueryResponse<DataType>, PaginationProps>({
        query: ({
            filters = options.filters ?? '',
            sort = options.sort ?? '',
            page = options.page ?? 0,
            pageSize = options.pageSize ?? 10,
        }) =>
            `${endpoint}${objectToQueryParams({
                filter: filters,
                sort,
                page,
                size: pageSize,
            })}`,
        merge: (currentCache, newItems) => {
            if (newItems.first) {
                return newItems;
            }
            return { ...newItems, content: [...currentCache.content, ...newItems.content] };
        },
        serializeQueryArgs: ({ endpointName, queryArgs: { page, ...rest } }) => {
            // Page is excluded from query key.
            // If other args are equal and page changes, we accumulate the data in query cache with merge function.
            return `${endpointName}(${JSON.stringify(rest)})`;
        },
        forceRefetch: ({ currentArg, previousArg }) => {
            return currentArg !== previousArg;
        },
        providesTags: options.providesTags,
    });

export const paginatedQueryWithFilters = <DataType>(
    builder: EndpointBuilder<
        BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, Record<string, unknown>, FetchBaseQueryMeta>,
        string,
        'portalAPI'
    >,
    endpoint: string,
    options: Options<DataType, string> = {}
) =>
    builder.query<QueryResponse<DataType>, PaginationProps>({
        query: ({
            filters = options.filters ?? '',
            sort = options.sort ?? '',
            page = options.page ?? 0,
            pageSize = options.pageSize ?? 10,
        }) =>
            `${endpoint}${objectToQueryParams({
                filter: filters,
                sort,
                page,
                size: pageSize,
            })}`,
        providesTags: options.providesTags,
    });
