import { Navigate } from 'react-router-dom';
import { FeatureFlag } from 'types/features';
import { useIsFeatureEnabled } from 'utils/useFeatureFlags';

export interface ProtectedRouteProps {
    element: JSX.Element;
    redirectPath: string;
    requiredFeature: FeatureFlag;
}

export const ProtectedRoute = (props: ProtectedRouteProps): JSX.Element => {
    const { element, redirectPath, requiredFeature } = props;
    const isFlowEnabled = useIsFeatureEnabled(requiredFeature);

    if (isFlowEnabled) {
        return element;
    }

    return <Navigate to={redirectPath} replace />;
};
