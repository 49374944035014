import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import MainLayout from 'layout/MainLayout';
import { FeatureFlag } from 'types/features';
import AuthGuard from 'utils/route-guard/AuthGuard';
import { ProtectedRoute } from './ProtectedRoute';
import SnackbarAlertProvider from 'components/@extended/SnackbarProvider';
import JobPositionPageEdit from '../pages/job-positions/JobPositionPageEdit';
import JobPositionPageAdd from '../pages/job-positions/JobPositionPageAdd';

// render - sample page
const BillableMattersPage = Loadable(lazy(() => import('pages/billable-clients/BillableMattersPage')));
const EmployeesPage = Loadable(lazy(() => import('pages/employees/EmployeesPage')));
const EmployeePageAdd = Loadable(lazy(() => import('pages/employees/EmployeePageAdd')));
const EmployeePageEdit = Loadable(lazy(() => import('pages/employees/EmployeePageEdit')));
const EffortsPage = Loadable(lazy(() => import('pages/efforts/EffortsPage')));
const EffortPageAdd = Loadable(lazy(() => import('pages/efforts/EffortPageAdd')));
const GroupsPage = Loadable(lazy(() => import('pages/groups/GroupsPage')));
const GroupPage = Loadable(lazy(() => import('pages/groups/GroupPage')));
const GroupPageAdd = Loadable(lazy(() => import('pages/groups/GroupPageAdd')));
const GroupPageEdit = Loadable(lazy(() => import('pages/groups/GroupPageEdit')));
const EffortPageEdit = Loadable(lazy(() => import('pages/efforts/EffortPageEdit')));
const OfficestPage = Loadable(lazy(() => import('pages/offices/OfficesPage')));
const OfficePageAdd = Loadable(lazy(() => import('pages/offices/OfficePageAdd')));
const OfficePageEdit = Loadable(lazy(() => import('pages/offices/OfficePageEdit')));
const PracticeAreasListPage = Loadable(lazy(() => import('pages/practice-areas/PracticeAreasListPage')));
const PracticeAreaPageAdd = Loadable(lazy(() => import('pages/practice-areas/PracticeAreaPageAdd')));
const PracticeAreaPageEdit = Loadable(lazy(() => import('pages/practice-areas/PracticeAreaPageEdit')));
const TeamPageAdd = Loadable(lazy(() => import('pages/teams/TeamPageAdd')));
const TeamPageEdit = Loadable(lazy(() => import('pages/teams/TeamPageEdit')));
const TeamsListPage = Loadable(lazy(() => import('pages/teams/TeamsListPage')));
const JobRolesListPage = Loadable(lazy(() => import('pages/job-roles/JobRolesListPage')));
const JobRolePageAdd = Loadable(lazy(() => import('pages/job-roles/JobRolePageAdd')));
const JobRolePageEdit = Loadable(lazy(() => import('pages/job-roles/JobRolePageEdit')));
const JobPositionsListPage = Loadable(lazy(() => import('pages/job-positions/JobPositionsListPage')));
const ContractsListPage = Loadable(lazy(() => import('pages/contracts/ContractsListPage')));
const ContractPage = Loadable(lazy(() => import('pages/contracts/ContractPage')));
const ContractPageAdd = Loadable(lazy(() => import('pages/contracts/ContractPageAdd')));
const ContractPageEdit = Loadable(lazy(() => import('pages/contracts/ContractPageEdit')));
const ClassifiersPage = Loadable(lazy(() => import('pages/classifiers/ClassifiersPage')));
const FinancialConditionsPage = Loadable(lazy(() => import('pages/financial-conditions/FinancialConditionsPage')));
const PricelistsPage = Loadable(lazy(() => import('pages/pricelists/PricelistsPage')));
const MattersListPage = Loadable(lazy(() => import('pages/matters/MattersListPage')));
const MatterPage = Loadable(lazy(() => import('pages/matters/MatterPage')));
const MatterPageAdd = Loadable(lazy(() => import('pages/matters/MatterPageAdd')));
const MatterPageEdit = Loadable(lazy(() => import('pages/matters/MatterPageEdit')));
const TasksPage = Loadable(lazy(() => import('pages/tasks/TasksPage')));
const TaskPageDuplicate = Loadable(lazy(() => import('pages/tasks/TaskPageDuplicate')));
const TaskPageAdd = Loadable(lazy(() => import('pages/tasks/TaskPageAdd')));
const TaskPageEdit = Loadable(lazy(() => import('pages/tasks/TaskPageEdit')));
const TimecardListPage = Loadable(lazy(() => import('pages/timecards/TimecardListPage')));
const TimecardPageForm = Loadable(lazy(() => import('pages/timecards/TimecardPageForm')));
const DuplicateTimecardPage = Loadable(lazy(() => import('pages/timecards/DuplicateTimecardPage')));
const TimecardPage = Loadable(lazy(() => import('pages/timecards/TimecardPage')));
const TimecardEditPage = Loadable(lazy(() => import('pages/timecards/TimecardEditPage')));
const FinishTimecardPageForm = Loadable(lazy(() => import('pages/timecards/FinishTimecardPageForm')));
const DashBoardPage = Loadable(lazy(() => import('pages/dashboard/DashBoard')));
const ConflictCheckReports = Loadable(lazy(() => import('pages/coi/ConflictCheckReports')));
const CoiCheckPage = Loadable(lazy(() => import('pages/coi/CoiCheckPage')));
const ContactPageEdit = Loadable(lazy(() => import('pages/contacts/ContactPageEdit')));
const ContactPageAdd = Loadable(lazy(() => import('pages/contacts/ContactPageAdd')));
const ContactsPage = Loadable(lazy(() => import('pages/contacts/ContactsPage')));
const ContactPage = Loadable(lazy(() => import('pages/contacts/ContactPage')));
const InvoicesPage = Loadable(lazy(() => import('pages/invoices/InvoicesPage')));
const InvoicePage = Loadable(lazy(() => import('pages/invoices/InvoicePage')));
const CorporateRolePage = Loadable(lazy(() => import('pages/corporate-structure/CorporateRolePage')));

const BREADCRUMB_COLLECTION = {
    contacts: [{ path: '/contacts', title: 'contacts.contacts' }],
    employees: [{ path: '/employees', title: 'employees.colleagues' }],
    efforts: [{ path: '/efforts', title: 'efforts.efforts' }],
    groups: [{ path: '/groups', title: 'groups.groups' }],
    contracts: [{ path: '/contracts', title: 'contracts.contracts' }],
    matters: [{ path: '/matters', title: 'matters.matters' }],
    offices: [{ path: '/offices', title: 'offices.offices' }],
    practiceAreas: [{ path: '/practice-areas', title: 'practice-areas.practice-areas' }],
    teams: [{ path: '/teams', title: 'teams.teams' }],
    invoices: [{ path: '/invoices', title: 'invoices.invoices' }],
    jobRoles: [{ path: '/job-roles', title: 'job-roles.job-roles' }],
    jobPositions: [{ path: '/job-positions', title: 'job-positions.job-positions' }],
    financialConditions: [{ path: '/financial-conditions', title: 'financial-conditions.financial-conditions' }],
    timecards: [{ path: '/timecards', title: 'timecards.timecards' }],
    coiChecks: [{ path: '/coi-checks', title: 'coi.coi-check' }],
};
// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    children: [
        {
            path: '/',
            element: (
                <AuthGuard>
                    <MainLayout />
                </AuthGuard>
            ),
            children: [
                {
                    path: '/billable-clients',
                    element: <BillableMattersPage />,
                },
                {
                    path: 'home',
                    element: <DashBoardPage />,
                },
                {
                    path: 'contacts',
                    element: <ContactsPage />,
                },
                {
                    path: '/contact/:id',
                    element: <ContactPage />,
                    breadcrumbs: BREADCRUMB_COLLECTION['contacts'],
                },
                {
                    path: '/contact/new',
                    element: <ContactPageAdd />,
                    breadcrumbs: BREADCRUMB_COLLECTION['contacts'],
                },
                {
                    path: '/contact/:id/edit',
                    element: <ContactPageEdit />,
                    breadcrumbs: BREADCRUMB_COLLECTION['contacts'],
                },
                {
                    path: '/contact/:contactId/matter/new',
                    element: <MatterPageAdd />,
                    breadcrumbs: BREADCRUMB_COLLECTION['matters'],
                },
                {
                    path: '/contact/:contactId/contract/new',
                    element: <ContractPageAdd />,
                    breadcrumbs: BREADCRUMB_COLLECTION['contracts'],
                },
                {
                    path: '/contact/:contactId/invoice/:id',
                    element: <InvoicePage />,
                    breadcrumbs: BREADCRUMB_COLLECTION['invoices'],
                },
                {
                    path: '/matter/:matterId/invoice/:id',
                    element: <InvoicePage />,
                    breadcrumbs: BREADCRUMB_COLLECTION['invoices'],
                },
                {
                    path: 'contracts',
                    element: <ContractsListPage />,
                },
                { path: '/contract/:id', element: <ContractPage />, breadcrumbs: BREADCRUMB_COLLECTION['contracts'] },
                {
                    path: '/contract/new',
                    element: <ContractPageAdd />,
                    breadcrumbs: BREADCRUMB_COLLECTION['contracts'],
                },
                {
                    path: '/contract/:id/edit',
                    element: <ContractPageEdit />,
                    breadcrumbs: BREADCRUMB_COLLECTION['contracts'],
                },
                {
                    path: '/roles-matrix',
                    element: <CorporateRolePage />,
                },
                {
                    path: 'matters',
                    element: <MattersListPage />,
                },
                { path: '/matter/:id', element: <MatterPage />, breadcrumbs: BREADCRUMB_COLLECTION['matters'] },
                {
                    path: '/matter/new',
                    element: <MatterPageAdd />,
                    breadcrumbs: BREADCRUMB_COLLECTION['matters'],
                },
                {
                    path: '/matter/:id/edit',
                    element: <MatterPageEdit />,
                    breadcrumbs: BREADCRUMB_COLLECTION['matters'],
                },
                {
                    path: '/matter/:matterId/timecards/new',
                    element: <TimecardPageForm type="add" />,
                    breadcrumbs: BREADCRUMB_COLLECTION['matters'],
                },
                {
                    path: 'employees',
                    element: <EmployeesPage />,
                },
                {
                    path: '/employee/:id',
                    element: <EmployeePageEdit />,
                    breadcrumbs: BREADCRUMB_COLLECTION['employees'],
                },
                {
                    path: '/employee/new',
                    element: <EmployeePageAdd />,
                    breadcrumbs: BREADCRUMB_COLLECTION['employees'],
                },
                {
                    path: 'efforts',
                    element: <EffortsPage />,
                },
                {
                    path: 'effort/:id',
                    element: <EffortPageEdit />,
                    breadcrumbs: BREADCRUMB_COLLECTION['efforts'],
                },
                {
                    path: '/effort/new',
                    element: <EffortPageAdd />,
                    breadcrumbs: BREADCRUMB_COLLECTION['efforts'],
                },
                {
                    path: 'groups',
                    element: <GroupsPage />,
                },
                { path: '/group/:id', element: <GroupPage />, breadcrumbs: BREADCRUMB_COLLECTION['groups'] },
                {
                    path: '/group/new',
                    element: <GroupPageAdd />,
                    breadcrumbs: BREADCRUMB_COLLECTION['groups'],
                },
                {
                    path: '/group/:id/edit',
                    element: <GroupPageEdit />,
                    breadcrumbs: BREADCRUMB_COLLECTION['groups'],
                },
                {
                    path: '/contact/:contactId/effort/new',
                    element: <EffortPageAdd />,
                    breadcrumbs: BREADCRUMB_COLLECTION['efforts'],
                },
                {
                    path: 'offices',
                    element: <OfficestPage />,
                },
                {
                    path: '/office/:id',
                    element: <OfficePageEdit />,
                    breadcrumbs: BREADCRUMB_COLLECTION['offices'],
                },
                {
                    path: '/office/new',
                    element: <OfficePageAdd />,
                    breadcrumbs: BREADCRUMB_COLLECTION['offices'],
                },
                {
                    path: 'practice-areas',
                    element: <PracticeAreasListPage />,
                },
                {
                    path: '/practice-area/:id',
                    element: <PracticeAreaPageEdit />,
                    breadcrumbs: BREADCRUMB_COLLECTION['practiceAreas'],
                },
                {
                    path: '/practice-area/new',
                    element: <PracticeAreaPageAdd />,
                    breadcrumbs: BREADCRUMB_COLLECTION['practiceAreas'],
                },
                {
                    path: 'teams',
                    element: <TeamsListPage />,
                },
                { path: '/team/:id', element: <TeamPageEdit />, breadcrumbs: BREADCRUMB_COLLECTION['teams'] },
                { path: '/team/new', element: <TeamPageAdd />, breadcrumbs: BREADCRUMB_COLLECTION['teams'] },
                {
                    path: 'invoices',
                    element: (
                        <SnackbarAlertProvider>
                            <InvoicesPage />
                        </SnackbarAlertProvider>
                    ),
                },
                {
                    path: '/invoice/:id',
                    element: (
                        <SnackbarAlertProvider>
                            <InvoicePage />
                        </SnackbarAlertProvider>
                    ),
                    breadcrumbs: BREADCRUMB_COLLECTION['invoices'],
                },
                {
                    path: 'job-roles',
                    element: <JobRolesListPage />,
                },
                {
                    path: '/job-role/:id',
                    element: <JobRolePageEdit />,
                    breadcrumbs: BREADCRUMB_COLLECTION['jobRoles'],
                },
                {
                    path: '/job-role/new',
                    element: <JobRolePageAdd />,
                    breadcrumbs: BREADCRUMB_COLLECTION['jobRoles'],
                },
                {
                    path: 'job-positions',
                    element: <JobPositionsListPage />,
                },
                {
                    path: '/job-position/:id',
                    element: <JobPositionPageEdit />,
                    breadcrumbs: BREADCRUMB_COLLECTION['jobPositions'],
                },
                {
                    path: '/job-position/new',
                    element: <JobPositionPageAdd />,
                    breadcrumbs: BREADCRUMB_COLLECTION['jobPositions'],
                },
                {
                    path: 'classifiers',
                    element: <ClassifiersPage />,
                },
                {
                    path: 'financial-conditions',
                    element: <FinancialConditionsPage />,
                },
                {
                    path: 'coi-checks',
                    element: (
                        <ProtectedRoute
                            element={<ConflictCheckReports />}
                            redirectPath="/home"
                            requiredFeature={FeatureFlag.COI_CHECK_FLOW_ENABLED}
                        />
                    ),
                    breadcrumbs: BREADCRUMB_COLLECTION['coiChecks'],
                },
                {
                    path: 'coi-check/:id',
                    element: (
                        <ProtectedRoute
                            element={<CoiCheckPage />}
                            redirectPath="/home"
                            requiredFeature={FeatureFlag.COI_CHECK_FLOW_ENABLED}
                        />
                    ),
                    breadcrumbs: BREADCRUMB_COLLECTION['coiChecks'],
                },
                {
                    path: 'pricelists',
                    element: <PricelistsPage />,
                },
                {
                    path: '/tasks',
                    element: <TasksPage />,
                },
                {
                    path: '/task/new',
                    element: <TaskPageAdd />,
                },
                {
                    path: '/task/new/:id',
                    element: <TaskPageDuplicate />,
                },
                {
                    path: '/task/:id',
                    element: <TaskPageEdit />,
                },
                { path: 'timecards', element: <TimecardListPage />, breadcrumbs: BREADCRUMB_COLLECTION['timecards'] },
                {
                    path: 'timecards/new',
                    element: <TimecardPageForm type="add" />,
                    breadcrumbs: BREADCRUMB_COLLECTION['timecards'],
                },
                {
                    path: 'timecards/finish/:id',
                    element: <FinishTimecardPageForm />,
                    breadcrumbs: BREADCRUMB_COLLECTION['timecards'],
                },
                {
                    path: 'timecards/finish',
                    element: <FinishTimecardPageForm />,
                    breadcrumbs: BREADCRUMB_COLLECTION['timecards'],
                },
                {
                    path: 'timecards/duplicate/:id',
                    element: <DuplicateTimecardPage />,
                    breadcrumbs: BREADCRUMB_COLLECTION['timecards'],
                },
                {
                    path: 'timecards/:id',
                    element: <TimecardPage />,
                    breadcrumbs: BREADCRUMB_COLLECTION['timecards'],
                },
                {
                    path: 'timecards/:id/edit',
                    element: <TimecardEditPage />,
                    breadcrumbs: BREADCRUMB_COLLECTION['timecards'],
                },
            ],
        },
    ],
};

export default MainRoutes;
