import { Box, useTheme } from '@mui/material';
import DrawerPortal from 'components/drawer/DrawerPortal';
import TimecardCenterContent from 'pages/timecards/timecard-center/TimecardCenterContent';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'store';
import { closeSubDrawer, toggleSubDrawer } from 'store/reducers/subdrawer';
import StartTrackingButton from './StartTrackingButton';
import TrackingWidget from './TrackingWidget';
import useTrackedTimecard from './useTrackedTimecard';

const SidebarTracker = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const isDrawerOpen = useSelector((state) => state.menu.drawerOpen);
    const isSubDrawerOpen = useSelector((state) => state.subDrawer.activeDrawerId === 'timecard-center');
    const currentTimecard = useTrackedTimecard();

    const toggleDrawer = useCallback(() => {
        dispatch(toggleSubDrawer('timecard-center'));
    }, []);

    const closeDrawer = useCallback(() => {
        dispatch(closeSubDrawer());
    }, []);

    const getTrackingComponent = (): JSX.Element | null => {
        if (currentTimecard) {
            return (
                <TrackingWidget
                    timecard={currentTimecard}
                    toggleTimecardModal={toggleDrawer}
                    closeTimecardModal={closeDrawer}
                />
            );
        }

        if (!isSubDrawerOpen) {
            return (
                <Box p={isDrawerOpen ? 2 : 1}>
                    <StartTrackingButton toggleDrawer={toggleDrawer} isDrawerOpen={isDrawerOpen} theme={theme} />
                </Box>
            );
        }

        return null;
    };

    return (
        <>
            <DrawerPortal
                content={<TimecardCenterContent />}
                drawerId="timecard-center"
                titleId="timecards.start-tracking"
                sx={{ width: theme.breakpoints.down('sm') ? '85vw' : '60vw', maxWidth: '700px' }}
                type="sub"
            />
            {getTrackingComponent()}
        </>
    );
};

export default SidebarTracker;
