import { Stack, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { format } from 'date-fns';

interface AddressedTaskCreatedInfoProps {
    createdAt?: number;
}

const AddressedTaskCreatedInfo: React.FC<AddressedTaskCreatedInfoProps> = ({ createdAt }) => {
    return (
        <Stack
            direction="column"
            component="div"
            sx={{
                pb: '20px',
            }}
        >
            {createdAt && (
                <Typography sx={{ color: 'text.secondary' }}>
                    <FormattedMessage
                        id="tasks.view-form.created-at"
                        values={{ date: format(new Date(createdAt), 'HH:mm yyyy-MM-dd') }}
                    />
                </Typography>
            )}
        </Stack>
    );
};

export default AddressedTaskCreatedInfo;
