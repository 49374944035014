// material-ui
import { Theme } from '@mui/material/styles';

// ==============================|| OVERRIDES - ALERT ||============================== //

export default function Alert(theme: Theme) {
    return {
        MuiAlert: {
            styleOverrides: {
                root: {
                    color: theme.palette.text.primary,
                    fontSize: '0.875rem',
                    fontWeight: 600,
                    gap: 8,
                    borderRadius: '8px',
                    border: '1px solid transparent',
                    display: 'flex',
                    justifyContent: 'start',
                    alignItems: 'center',
                    padding: '4px 16px 4px 12px',
                },
                icon: {
                    fontSize: '1rem',
                    background: 'transparent',
                    color: 'initial',
                    width: 24,
                    margin: 0,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    '.MuiSvgIcon-root': {
                        fontSize: '1rem',
                    },
                },
                message: {
                    padding: 0,
                },
                filledSuccess: {
                    borderColor: theme.palette.success.main,
                    backgroundColor: theme.palette.success.light,
                },
                filledWarning: {
                    borderColor: theme.palette.warning.main,
                    backgroundColor: theme.palette.warning.light,
                },
                filledError: {
                    borderColor: theme.palette.error.main,
                    backgroundColor: theme.palette.error.light,
                },
                filledInfo: {
                    borderColor: theme.palette.info.main,
                    backgroundColor: theme.palette.info.lighter,
                },
                action: {
                    padding: 0,
                    '& .MuiIconButton-root': {
                        bakgroundColor: 'inherit',
                        background: 'transparent',
                        color: theme.palette.text.primary,
                        width: 24,
                        height: 24,
                        margin: 0,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: '50%',
                        '& .MuiSvgIcon-root': {
                            fontSize: '1rem',
                        },
                        ':hover': {
                            background: theme.palette.grey[400],
                        },
                    },
                },
            },
        },
    };
}
