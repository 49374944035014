// ==============================|| OVERRIDES - LINK ||============================== //

import { Theme } from '@mui/material/styles';

export default function Link(theme: Theme) {
    return {
        MuiLink: {
            defaultProps: {
                underline: 'hover',
            },
            styleOverrides: {
                root: {
                    color: theme.palette.text.primary,
                    ':hover': { color: theme.palette.text.link, cursor: 'pointer' },
                },
                '.MuiLink-root': {
                    color: theme.palette.text.primary,
                },
                colorPrimary: { color: theme.palette.text.primary },
                colorSecondary: { color: theme.palette.text.secondary },
            },
        },
    };
}
