import {
    MatterBillingSummaryDto,
    MatterCreateDto,
    MatterDto,
    MatterListItem,
    MatterListItemMin,
    MatterStateCountDto,
    MatterUpdateDto,
    UpdateHourlyRatesDto,
} from 'types/matters';
import { portalAPI } from './portalAPI';
import { HourlyRate } from 'types/pricelists';
import { accumulatedQueryWithFilters, paginatedQueryWithFilters } from './util/endpointBuilder';
import { filteredAndSortedQuery } from './util/filteredAndSortedQuery';
import { CoiCheck } from '../../types/coi-check';
import { HistoryEntityType, HistoryFieldType } from 'types/histories';

// Inject notifications tag to refetch them when adding case. So if you assign yourself you will get the notification instantly
export const APIMattersSlice = portalAPI
    .enhanceEndpoints({
        addTagTypes: [
            'Matter',
            'Notifications',
            'MattersListItem',
            'MattersMin',
            'DashboardMatters',
            'DashboardMattersMin',
            'MatterCoiChecks',
            'MatterSummary',
            'MatterRates',
            'GetMatterStates',
            'HistoryListItems',
        ],
    })
    .injectEndpoints({
        endpoints: (builder) => ({
            getMatterById: builder.query<MatterDto, string>({
                query: (entityId) => `/v1/matters/${entityId}`,
                providesTags: (_1, _2, arg) => [{ type: 'Matter', id: arg }],
                keepUnusedDataFor: 0,
            }),
            getMatterCoiChecks: builder.query<CoiCheck[], string>({
                query: (entityId) => `/v1/matters/${entityId}/coi-checks`,
                providesTags: ['MatterCoiChecks'],
            }),
            getMattersList: filteredAndSortedQuery<MatterListItemMin>(builder, '/v1/matters/min', {
                providesTags: ['MattersMin'],
            }),
            getMattersWithFilters: accumulatedQueryWithFilters<MatterListItem>(builder, '/v1/matters', {
                providesTags: ['MattersListItem'],
            }),
            getPaginatedMattersWithFilters: paginatedQueryWithFilters<MatterListItem>(builder, '/v1/matters', {
                providesTags: ['MattersListItem'],
            }),
            getDashboardMattersList: filteredAndSortedQuery<MatterListItemMin>(builder, '/v1/matters/dashboard/min', {
                providesTags: ['DashboardMattersMin'],
            }),
            getPaginatedDashboardMattersWithFilters: paginatedQueryWithFilters<MatterListItem>(
                builder,
                '/v1/matters/dashboard',
                {
                    providesTags: ['DashboardMatters'],
                }
            ),
            getMatterBillingSummary: builder.query<MatterBillingSummaryDto, string | number>({
                query: (entityId) => `/v1/matters/${entityId}/billing-summary`,
                keepUnusedDataFor: 0,
                providesTags: ['MatterSummary'],
            }),
            getMattersStates: builder.query<MatterStateCountDto, { clientIds: number[]; searchText?: string }>({
                query: ({ ...body }) => ({
                    url: `/v1/matters/states`,
                    method: 'PUT',
                    body,
                }),
                providesTags: ['GetMatterStates'],
            }),
            findMatterRates: builder.query<HourlyRate[], string>({
                query: (entityId) => `/v1/matters/${entityId}/rates`,
                keepUnusedDataFor: 0,
                providesTags: (_1, _2, arg) => [{ type: 'MatterRates', id: arg }],
            }),
            addMatter: builder.mutation<MatterDto, MatterCreateDto>({
                query: ({ ...body }) => ({
                    url: `/v1/matters`,
                    method: 'POST',
                    body,
                }),
                invalidatesTags: [
                    'Notifications',
                    'MattersMin',
                    'DashboardMatters',
                    'DashboardMattersMin',
                    'GetMatterStates',
                ],
            }),
            updateMatter: builder.mutation<MatterDto, MatterUpdateDto>({
                query: ({ id, ...body }) => ({
                    url: `/v1/matters/${id}`,
                    method: 'PUT',
                    body,
                }),
                invalidatesTags: (_1, _2, arg) =>
                    arg.id
                        ? [
                              'MattersListItem',
                              'MatterSummary',
                              'MattersMin',
                              'DashboardMatters',
                              'DashboardMattersMin',
                              'GetMatterStates',
                              { type: 'Matter', id: arg.id },
                              {
                                  type: 'HistoryListItems',
                                  historySelector: {
                                      entityType: HistoryEntityType.MATTER,
                                      entityId: arg.id,
                                      fieldType: HistoryFieldType.MATTER_STATE_TYPE_CODE,
                                  },
                              },
                              {
                                  type: 'HistoryListItems',
                                  historySelector: {
                                      entityType: HistoryEntityType.MATTER,
                                      entityId: arg.id,
                                      fieldType: HistoryFieldType.RESPONSIBLE_EMPLOYEE,
                                  },
                              },
                              {
                                  type: 'HistoryListItems',
                                  historySelector: {
                                      entityType: HistoryEntityType.MATTER,
                                      entityId: arg.id,
                                      fieldType: HistoryFieldType.SUPERVISING_EMPLOYEE,
                                  },
                              },
                          ]
                        : [
                              'MattersListItem',
                              'MatterSummary',
                              'MattersMin',
                              'DashboardMatters',
                              'DashboardMattersMin',
                              'GetMatterStates',
                          ],
            }),
            updateMatterPricelist: builder.mutation<MatterDto, Partial<UpdateHourlyRatesDto> & { id: string }>({
                query: ({ id, ...body }) => ({
                    url: `/v1/matters/${id}/pricelist`,
                    method: 'PUT',
                    body,
                }),
                invalidatesTags: ['MatterSummary', 'MatterRates'],
            }),
            deleteMatter: builder.mutation<boolean, { id: string }>({
                query: ({ id }) => ({
                    url: `/v1/matters/${id}`,
                    method: 'DELETE',
                }),
                invalidatesTags: [
                    'MattersListItem',
                    'MattersMin',
                    'DashboardMatters',
                    'DashboardMattersMin',
                    'GetMatterStates',
                ],
            }),
        }),
        overrideExisting: false,
    });

export const {
    useGetMatterByIdQuery,
    useGetMattersListQuery,
    useGetMattersStatesQuery,
    useGetDashboardMattersListQuery,
    useGetMatterBillingSummaryQuery,
    useFindMatterRatesQuery,
    useGetMatterCoiChecksQuery,
    useLazyGetMattersWithFiltersQuery,
    useLazyGetPaginatedMattersWithFiltersQuery,
    useLazyGetPaginatedDashboardMattersWithFiltersQuery,
    useAddMatterMutation,
    useUpdateMatterMutation,
    useUpdateMatterPricelistMutation,
    useDeleteMatterMutation,
} = APIMattersSlice;
