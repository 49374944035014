import React, { useCallback, useEffect, useMemo, useState } from 'react';

// MUI
import { Link, Box, OutlinedInput, Typography, Grid, Stack, Pagination, Skeleton } from '@mui/material';
import useTheme from '@mui/system/useTheme';
import { SearchOutlined } from '@ant-design/icons';

// Third-party
import { Link as RouterLink } from 'react-router-dom';
import { useAsyncDebounce } from 'react-table';
import { t } from 'utils/getTranslations';

// Store
import { useLazyGetPaginatedDashboardMattersWithFiltersQuery } from 'store/api/matters';

// Project imports
import MyCaseActionButtons from './MyCaseActionButtons';
import NoData from 'components/NoData';

interface MyCasesProps {
    setMyCasesCount?: (count: number) => void;
}

const MyCasesTab = (props: MyCasesProps) => {
    const { setMyCasesCount } = props;
    const [searchText, setSearchText] = useState('');

    const [currentPage, setCurrentPage] = useState(1);
    const [isInitialFetchDone, setIsInitialFetchDone] = useState(false);
    const theme = useTheme();
    const [fetchData, { data, isFetching }] = useLazyGetPaginatedDashboardMattersWithFiltersQuery();

    const handlePageChange = useCallback((index: number) => {
        setCurrentPage(index);
        getList(index, searchText);
    }, []);

    const handleSearchChange = useCallback((searchText: string) => {
        setSearchText(searchText);
    }, []);

    const getList = async (index: number, searchText: string) => {
        let filter = '';
        if (searchText) {
            const wordsToSearch = searchText.split(' ');
            filter = wordsToSearch.join(',');
        }

        await fetchData({
            pageSize: 10,
            page: index - 1,
            sort: 'openedDate,desc',
            filters: filter,
        }).unwrap();
    };

    useEffect(() => {
        if (!isInitialFetchDone && !data) {
            getList(1, searchText);
            setIsInitialFetchDone(true);
        }
        if (setMyCasesCount) {
            setMyCasesCount(data?.totalElements || 0);
        }
    }, [data]);

    const onSearchInputChange = useAsyncDebounce((searchText: string) => {
        setCurrentPage(1);
        getList(1, searchText);
    }, 600);

    if (!data) {
        return null;
    }

    return (
        <Box>
            <React.Fragment>
                <OutlinedInput
                    value={searchText}
                    onChange={(e) => {
                        handleSearchChange(e.target.value);
                        onSearchInputChange(e.target.value);
                    }}
                    placeholder={t('search')}
                    startAdornment={<SearchOutlined />}
                    sx={{ width: 'calc(100% - 40px)', margin: '0 20px' }}
                />
                {!data?.content.length ||
                    (data.totalElements === 0 && (
                        <Stack direction="row" justifyContent="center" sx={{ marginTop: '20px' }}>
                            <NoData titleId="dashboard.no-cases-found" />
                        </Stack>
                    ))}
                {isFetching ? (
                    <GridLoader />
                ) : (
                    data.content.map((matter) => {
                        return (
                            <Grid container sx={caseElementContainerStyles} key={`my-matter-${matter.id}`}>
                                <Grid item key={matter.id} xs={6}>
                                    <Stack>
                                        <Link
                                            to={`/matter/${matter.id}`}
                                            sx={{ fontWeight: 600 }}
                                            component={RouterLink}
                                        >
                                            {matter.fullName ?? '-'}
                                        </Link>
                                        {matter.clientParty?.id ? (
                                            <Link
                                                to={`/contact/${matter.clientParty.id}`}
                                                sx={{ whiteSpace: 'nowrap', color: theme.palette.text.secondary }}
                                                component={RouterLink}
                                            >
                                                <Typography
                                                    variant="caption"
                                                    color={theme.palette.text.secondary}
                                                    sx={{
                                                        ':hover': {
                                                            color: theme.palette.text.link,
                                                        },
                                                    }}
                                                >
                                                    {matter.clientParty?.name ?? '-'}
                                                </Typography>
                                            </Link>
                                        ) : null}
                                    </Stack>
                                </Grid>
                                <Grid item xs={6} alignSelf="center">
                                    <Stack direction="row" spacing={1} justifyContent="flex-end" alignItems="center">
                                        <MyCaseActionButtons matter={matter} />
                                    </Stack>
                                </Grid>
                            </Grid>
                        );
                    })
                )}
                {data.totalPages > 1 ? (
                    <Stack direction="row" justifyContent="flex-end" sx={{ pt: '20px', px: '16px' }}>
                        <Pagination
                            count={data.totalPages}
                            page={currentPage}
                            onChange={(_, page) => handlePageChange(page)}
                            variant="contained"
                            shape="rounded"
                        />
                    </Stack>
                ) : null}
            </React.Fragment>
        </Box>
    );
};

export const GridLoader = ({ height = 40, numberOfRows = 10 }: { height?: number; numberOfRows?: number }) => {
    const content = useMemo(() => {
        const rows = [] as React.ReactNode[];

        for (let i = 0; i < numberOfRows; i++) {
            rows.push(
                <Grid container sx={caseElementContainerStyles} spacing={1} key={`row-${i}`}>
                    <Grid item xs={6}>
                        <Skeleton variant="rectangular" width={'100%'} height={height} />
                    </Grid>

                    <Grid item xs={6}>
                        <Skeleton variant="rectangular" width={'100%'} height={height} />
                    </Grid>
                </Grid>
            );
        }
        return rows;
    }, []);
    return <>{content}</>;
};

const caseElementContainerStyles = {
    padding: '20px',
    borderBottom: '1px solid #e0e0e0',
    '&:last-child': {
        borderBottom: 'none',
        paddingBottom: '0',
    },
};

export default MyCasesTab;
