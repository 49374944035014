import { BaseQueryFn, FetchArgs, FetchBaseQueryError, FetchBaseQueryMeta } from '@reduxjs/toolkit/dist/query';
import { MutationLifecycleApi } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { setStopwatchRunning, trackTimecard } from 'store/reducers/timecard';
import { TimecardDetails, TimecardDto, TimecardListItemData, UpdateTimecardsMatter } from 'types/timecards';
import { portalAPI } from './portalAPI';
import { accumulatedQueryWithFilters, paginatedQueryWithFilters } from './util/endpointBuilder';
import { objectToQueryParams } from 'utils/linkUtil';
import { dispatch, store } from 'store';
import { filteredAndSortedQuery } from './util/filteredAndSortedQuery';
import { NamedEntityMinDto } from 'types/other';
import { APIMattersSlice } from './matters';

export interface QueryParams {
    filter?: string;
    sort?: string | string[];
}

const paginationQueryEndpoints = ['getTimecardsWithFilters', 'getPaginatedTimecardsWithFilters'] as const;

/**
 * Manually updates given timecard in list caches
 */
const updateTimecardInLists = async (
    timecard: TimecardDetails,
    api: MutationLifecycleApi<
        number,
        BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, Record<string, unknown>, FetchBaseQueryMeta>,
        TimecardDetails,
        'portalAPI'
    >
) => {
    const queries = api.getState().portalAPI.queries;
    paginationQueryEndpoints.forEach((endpointName) => {
        const endpointQueries = Object.entries(queries)
            .filter((entry) => entry[1]?.endpointName === endpointName)
            .map((entry) => entry[1]);
        endpointQueries.forEach((query) => {
            api.dispatch(
                APITimecardsSlice.util.updateQueryData(endpointName, query?.originalArgs as any, (draft) => {
                    const index = draft.content.findIndex((tc) => tc.id === timecard.id);
                    if (index !== -1) {
                        draft.content[index] = timecard;
                    }
                })
            );
        });
    });
};

/**
 *
 * Manually updates given timecard in getTimecardById cache
 */
const updateGetTimeCardByIdCache = (
    timecard: TimecardDetails,
    api: MutationLifecycleApi<
        number,
        BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, Record<string, unknown>, FetchBaseQueryMeta>,
        TimecardDetails,
        'portalAPI'
    >
) => {
    api.dispatch(
        APITimecardsSlice.util.updateQueryData('getTimecardById', timecard.id, (draft) =>
            Object.assign(draft || {}, timecard)
        )
    );
};

export const APITimecardsSlice = portalAPI
    .enhanceEndpoints({ addTagTypes: ['Timecards', 'TrackedTimecard', 'FilteredAndSortedList'] })
    .injectEndpoints({
        endpoints: (builder) => ({
            getTimecardById: builder.query<TimecardDetails, number>({
                query: (id) => `/v1/timecards/${id}`,
                providesTags: (_1, _2, arg) => [{ type: 'Timecards', id: arg }],
            }),
            getTimecardsList: filteredAndSortedQuery<TimecardListItemData>(builder, '/v1/timecards', {
                providesTags: ['FilteredAndSortedList'],
            }),
            getLastAddedTimecardPracticeAreas: builder.query<
                NamedEntityMinDto[],
                { employeeId: string; matterId: number }
            >({
                query: ({ employeeId, matterId }) =>
                    `/v1/timecards/last-added-practice-areas?employeeId=${employeeId}&matterId=${matterId}`,
                keepUnusedDataFor: 0,
            }),
            getTimecardsWithFilters: accumulatedQueryWithFilters<TimecardListItemData>(builder, '/v1/timecards', {
                providesTags: ['Timecards'],
                sort: ['timecardDate,desc', 'timecardStatusTypeCode,asc', 'isStopwatchRunning,desc'],
            }),
            getPaginatedTimecardsWithFilters: paginatedQueryWithFilters<TimecardListItemData>(
                builder,
                '/v1/timecards',
                { providesTags: ['Timecards'], sort: ['timecardDate,desc', 'isStopwatchRunning,desc'] }
            ),
            getTimecardsExportList: builder.query<TimecardListItemData[], QueryParams>({
                query: ({ filter = 'timecardStatusTypeCode=out=(DRAFT)', sort = '' }) => ({
                    url: `/v1/timecards/export${objectToQueryParams({ filter, sort, page: 0, pageSize: 1000 })}`,
                    method: 'GET',
                }),
            }),
            addTimecard: builder.mutation<TimecardDetails, Partial<TimecardDto>>({
                query: (body) => ({
                    url: `/v1/timecards`,
                    method: 'POST',
                    body,
                }),
                invalidatesTags: ['Timecards', 'FilteredAndSortedList'],
            }),
            finishTimecard: builder.mutation<TimecardDetails, Partial<TimecardDto>>({
                query: (body) => ({
                    url: `/v1/timecards/finish`,
                    method: 'PUT',
                    body,
                }),
                invalidatesTags: (_1, _2, arg) =>
                    arg.id
                        ? ['TrackedTimecard', 'Timecards', 'FilteredAndSortedList', { type: 'Timecards', id: arg.id }]
                        : ['Timecards', 'FilteredAndSortedList'],
            }),
            getCurrentlyTrackedTimecard: builder.query<TimecardDetails | null, void>({
                query: () => '/v1/timecards/currently-tracking',
                providesTags: ['TrackedTimecard'],
                async onQueryStarted(_arg, api) {
                    try {
                        const { data: timecard } = await api.queryFulfilled;
                        if (timecard?.isStopwatchRunning) {
                            const { id: userId, activeTenantId } = store.getState().user;
                            api.dispatch(trackTimecard({ timecardId: timecard.id, userId, tenantId: activeTenantId }));
                        }
                        // eslint-disable-next-line no-empty
                    } catch {}
                },
            }),
            startTrackingTimecard: builder.mutation<TimecardDetails, number>({
                query: (id) => ({
                    url: `/v1/timecards/start-tracking/${id}`,
                    method: 'PUT',
                }),
                async onQueryStarted(timecardId, api) {
                    const { id: userId, activeTenantId } = store.getState().user;
                    api.dispatch(trackTimecard({ timecardId, userId, tenantId: activeTenantId }));
                    try {
                        await api.queryFulfilled;
                    } catch {
                        api.dispatch(setStopwatchRunning(false));
                    }
                },
                invalidatesTags: ['TrackedTimecard'],
            }),
            pauseTrackingTimecard: builder.mutation<TimecardDetails, number>({
                query: (id) => ({
                    url: `/v1/timecards/pause-tracking/${id}`,
                    method: 'PUT',
                }),
                async onQueryStarted(_arg, api) {
                    api.dispatch(setStopwatchRunning(false));
                    try {
                        await api.queryFulfilled;
                    } catch {
                        api.dispatch(setStopwatchRunning(true));
                    }
                },
                invalidatesTags: ['TrackedTimecard'],
            }),
            // Utility mutation for updating timecard in caches
            updateTimecardCache: builder.mutation<TimecardDetails, number>({
                query: (id) => ({
                    url: `/v1/timecards/${id}`,
                    method: 'GET',
                }),
                async onQueryStarted(_arg, api) {
                    try {
                        const { data: updatedTimecard } = await api.queryFulfilled;
                        updateTimecardInLists(updatedTimecard, api);
                        updateGetTimeCardByIdCache(updatedTimecard, api);
                        // eslint-disable-next-line no-empty
                    } catch {}
                },
            }),
            updateTimecard: builder.mutation<TimecardDetails, Partial<TimecardDto>>({
                query: ({ id, ...body }) => ({
                    url: `/v1/timecards/${id}`,
                    method: 'PUT',
                    body,
                }),
                invalidatesTags: (_1, _2, arg) =>
                    arg.id
                        ? ['TrackedTimecard', 'Timecards', 'FilteredAndSortedList', { type: 'Timecards', id: arg.id }]
                        : ['Timecards', 'FilteredAndSortedList'],
            }),
            updateTimecardsMatter: builder.mutation<any, UpdateTimecardsMatter>({
                query: ({ ...body }) => ({
                    url: `/v1/timecards/update-timecards-matter`,
                    method: 'PUT',
                    body,
                }),
                invalidatesTags: (_1, _2, arg) => ['Timecards', 'FilteredAndSortedList'],
            }),
            deleteTimecard: builder.mutation<boolean, { id: number; matterId?: number }>({
                query: ({ id }) => ({
                    url: `/v1/timecards/${id}`,
                    method: 'DELETE',
                }),
                async onQueryStarted(_arg, api) {
                    await api.queryFulfilled;
                    dispatch(APIMattersSlice.util.invalidateTags([{ type: 'Matter', id: _arg.matterId }]));
                },
                invalidatesTags: (_1, _2, arg) =>
                    arg.id
                        ? ['TrackedTimecard', 'Timecards', 'FilteredAndSortedList', { type: 'Timecards', id: arg.id }]
                        : ['Timecards', 'FilteredAndSortedList'],
            }),
        }),
        overrideExisting: false,
    });

export const {
    useGetTimecardByIdQuery,
    useGetTimecardsListQuery,
    useGetLastAddedTimecardPracticeAreasQuery,
    useLazyGetTimecardsWithFiltersQuery,
    useLazyGetTimecardsExportListQuery,
    useGetPaginatedTimecardsWithFiltersQuery,
    useAddTimecardMutation,
    useFinishTimecardMutation,
    useGetCurrentlyTrackedTimecardQuery,
    useStartTrackingTimecardMutation,
    usePauseTrackingTimecardMutation,
    useUpdateTimecardCacheMutation,
    useUpdateTimecardMutation,
    useUpdateTimecardsMatterMutation,
    useDeleteTimecardMutation,
} = APITimecardsSlice;
