import { useState, useEffect, useMemo } from 'react';
import { useGetClassifiersListQuery } from 'store/api/classifiers';
import { ClassifierData } from 'types/classifiers';

export const useClassifiers = ({ codes }: { codes: string[] }) => {
    const [classifierObject, setClassifierObject] = useState<Record<string, ClassifierData>>({});
    const [isLoadingObject, setIsLoadingObject] = useState(true);
    const { data: classifiers, isLoading } = useGetClassifiersListQuery();

    useEffect(() => {
        if (!classifiers) return;
        const collectedClassifiers = {} as Record<string, ClassifierData>;
        classifiers.forEach((classifier) => {
            if (codes.includes(classifier.code)) {
                collectedClassifiers[classifier.code] = classifier;
            }
        });
        setClassifierObject(collectedClassifiers);
        setIsLoadingObject(false);
    }, [classifiers]);
    const filteredClassifiers = useMemo(() => classifiers?.filter((item) => codes.includes(item.code)), [classifiers]);
    return {
        classifiers: classifierObject,
        classifierArray: filteredClassifiers ?? [],
        allClassifiers: classifiers ?? [],
        isLoading: isLoading || isLoadingObject,
    };
};
