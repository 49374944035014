import { useCallback, useMemo, useState } from 'react';
import { Link, matchRoutes, useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import MuiBreadcrumbs from '@mui/material/Breadcrumbs';
import { Typography, useTheme } from '@mui/material';

import { RightOutlined } from '@ant-design/icons';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useMutationObserver } from 'utils/useMutationObsserver';
import MainRoutes from 'routes/MainRoutes';

// ==============================|| BREADCRUMBS ||============================== //

const BREADCRUMB_STYLE = {
    textDecoration: 'none',
    fontSize: '12px',
    lineHeight: '20px',
};

const Breadcrumbs = (): React.ReactElement => {
    const theme = useTheme();
    const location = useLocation();
    const [extraCrumb, setExtraCrumb] = useState<string | undefined>(
        document.head.querySelector('meta[name="breadcrumb"]')?.getAttribute('content') ?? undefined
    );

    const getCurrentRoute = useCallback(() => {
        return matchRoutes([MainRoutes], location)?.pop();
    }, [MainRoutes, location]);

    const separatorIcon = (
        <ChevronRightIcon style={{ fontSize: '0.75rem', marginTop: 2, color: theme.palette.text.primary }} />
    );

    const handler = useCallback(() => {
        setExtraCrumb(document.head.querySelector('meta[name="breadcrumb"]')?.getAttribute('content') ?? undefined);
    }, []);

    useMutationObserver('head', handler);

    const newBreadcrumbs = useMemo(() => {
        const crumbs = [
            <Typography
                key="dashboard"
                component={Link}
                to={'/'}
                variant="h6"
                sx={BREADCRUMB_STYLE}
                color="textSecondary"
            >
                <FormattedMessage id={'dashboard.dashboard'} />
            </Typography>,
        ];

        const currentRoute = getCurrentRoute() as any;
        const hasBreadcrumbs = currentRoute && !!currentRoute?.route?.breadcrumbs;
        const shouldInculdeCurrentPath =
            currentRoute &&
            !!currentRoute?.route?.path &&
            !!currentRoute?.route?.title &&
            !!currentRoute?.route?.isBreadcrumb;

        if (hasBreadcrumbs) {
            currentRoute.route.breadcrumbs.forEach((crumb: { path: string; title: string }, index: number) =>
                crumbs.push(
                    <Typography
                        key={`${crumb.title}-${index}`}
                        component={Link}
                        to={crumb.path}
                        variant="h6"
                        sx={BREADCRUMB_STYLE}
                        color={
                            shouldInculdeCurrentPath || extraCrumb || index < currentRoute.route.breadcrumbs.length - 1
                                ? 'textSecondary'
                                : 'textPrimary'
                        }
                    >
                        <FormattedMessage id={crumb.title} />
                    </Typography>
                )
            );
        }

        if (shouldInculdeCurrentPath) {
            crumbs.push(
                <Typography
                    key={currentRoute.route.title}
                    component={Link}
                    to={currentRoute.route.path}
                    variant="h6"
                    sx={BREADCRUMB_STYLE}
                    color={extraCrumb ? 'textSecondary' : 'textPrimary'}
                >
                    <FormattedMessage id={currentRoute.route.title} />
                </Typography>
            );
        }

        if (extraCrumb) {
            crumbs.push(
                <Typography
                    key={extraCrumb}
                    variant="h6"
                    color="textPrimary"
                    sx={{ marginTop: '1px', ...BREADCRUMB_STYLE }}
                >
                    {extraCrumb}
                </Typography>
            );
        }
        return crumbs;
    }, [extraCrumb, getCurrentRoute]);

    return (
        <MuiBreadcrumbs
            aria-label="breadcrumb"
            maxItems={8}
            separator={separatorIcon}
            sx={{
                [theme.breakpoints.down('sm')]: {
                    display: 'none',
                },
            }}
        >
            {newBreadcrumbs}
        </MuiBreadcrumbs>
    );
};

export default Breadcrumbs;
