import CreditCardIcon from 'assets/images/icons/CreditCardIcon.svg?react';
import { useModal } from 'components/modals/useModal';
import useAlert from 'hooks/useAlert';
import { useCallback } from 'react';
import { useSelector } from 'store';
import { useGetEmployeesListQuery } from 'store/api/employees';
import { useAddExpenseMutation } from 'store/api/expenses';
import { useGetDashboardMattersListQuery } from 'store/api/matters';
import { ExpenseCreateDto, ExpenseForm } from 'types/expenses';
import { TimecardListItemData } from 'types/timecards';
import { t } from 'utils/getTranslations';
import { useClassifiers } from 'utils/useClassifiers';
import { CallForActionButton, CallForActionButtonTooltip } from '../../../components/buttons/CallForActionButton';
import { formatDateStringInput } from 'components/forms/inputs/DatePickerInput';
import ExpenseModal from 'components/modals/expenses/ExpenseModal';
import Loader from 'components/Loader';

type Props = {
    timecard: TimecardListItemData;
};

const AddExpenseIconButton = ({ timecard: timecardData }: Props): JSX.Element | null => {
    const userId = useSelector((state) => state.user.id);

    const { openModal, closeModal } = useModal();
    const { errorAlert } = useAlert();

    const [addExpense] = useAddExpenseMutation();
    const { data: employeeList, isLoading: isEmployeeListLoading } = useGetEmployeesListQuery({});
    // @ts-ignore
    const { data: matterList, isLoading: isMattersListLoading } = useGetDashboardMattersListQuery({});
    const { classifiers, isLoading: isLoadingClassifiers } = useClassifiers({ codes: ['CURRENCY', 'EXPENSE_TYPE'] });

    const handleExpenseCreate = useCallback(
        async (expenseDto: ExpenseCreateDto) => {
            try {
                await addExpense(expenseDto);
            } catch (e) {
                console.error('onSubmit error: ', e);
                errorAlert(t('forms.error-submitting-the-form'));
            }
        },
        [addExpense, errorAlert]
    );

    const openExpenseModal = useCallback((): void => {
        const preSelectedEmployee = employeeList?.find((val) => val.id === userId);
        const preSelectedMatter = matterList?.find((val) => val.id === timecardData?.matter.id);

        const initalData: ExpenseForm = {
            expenseType: null,
            currencyType: null,
            description: '',
            expenseAmount: '0',
            expenseDate: formatDateStringInput(new Date()),
            isNonBillable: false,
            employee: preSelectedEmployee ? { id: preSelectedEmployee?.id, name: preSelectedEmployee?.name } : null,
            matter: preSelectedMatter
                ? { id: preSelectedMatter?.id, name: preSelectedMatter?.name, fullName: preSelectedMatter.fullName }
                : null,
            timecardId: timecardData?.id ?? null,
            originalCurrencyType: null,
            expenseAmountOriginalCurrency: '0',
        };

        openModal(
            <ExpenseModal
                onChange={(value) => handleExpenseCreate(value)}
                initialData={initalData}
                onClose={closeModal}
                classifiers={classifiers}
                matterList={matterList}
                employeeList={employeeList}
                titleId={'expenses.add'}
                disableSelectMatter={true}
                readOnly={false}
            />,
            closeModal
        );
    }, [
        classifiers,
        employeeList,
        matterList,
        userId,
        closeModal,
        handleExpenseCreate,
        openModal,
        timecardData?.id,
        timecardData?.matter.id,
    ]);

    if (isEmployeeListLoading || isMattersListLoading || isLoadingClassifiers) {
        return <Loader />;
    }

    return (
        <CallForActionButtonTooltip title={t('timecards.add-expense')}>
            <CallForActionButton
                variant="contained"
                shape="rounded"
                color="info"
                aria-label={t('timecards.add-expense')}
                onClick={() => openExpenseModal()}
            >
                <CreditCardIcon />
            </CallForActionButton>
        </CallForActionButtonTooltip>
    );
};

export default AddExpenseIconButton;
