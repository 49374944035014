import { Box, Grid, Stack } from '@mui/material';
import { format } from 'date-fns';
import useAlert from 'hooks/useAlert';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'store';
import { useGetMattersListQuery } from 'store/api/matters';
import { useAddTimecardMutation, useGetLastAddedTimecardPracticeAreasQuery } from 'store/api/timecards';
import { MatterListItem } from 'types/matters';
import { TimecardDto } from 'types/timecards';
import { t } from 'utils/getTranslations';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import StopIcon from '@mui/icons-material/StopCircle';
import { closeSubDrawer } from 'store/reducers/subdrawer';
import { useStartTrackingTimecard } from '../useStartTrackingTimecard';
import SearchMyCasesCheckbox from '../form/SearchMyCasesCheckbox';
import SectionTitle from './SectionTitle';
import TimecardLanguageLabel from '../labels/TimecardLanguageLabel';
import { useClassifiers } from 'utils/useClassifiers';
import { myCasesFilter, openAndUnblockedCasesFilter } from 'components/tables/umbrella/filter-pieces/filterConstants';
import SelectInput from 'components/forms/inputs/rhf-inputs/SelectInput';
import { useForm } from 'react-hook-form';
import DateInput from 'components/forms/inputs/rhf-inputs/DateInput';
import HoursInput from 'components/forms/inputs/rhf-inputs/HoursInput';
import TextInput from 'components/forms/inputs/rhf-inputs/TextInput';
import { hoursToSeconds } from 'utils/time';
import PermissionButton from 'components/buttons/PermissionButton';

const defaultValues: TimecardDto = {
    id: null,
    description: '',
    matter: null,
    employee: null,
    timecardDate: format(new Date(), 'yyyy-MM-dd'),
    isNonBillable: false,
    multiplierRate: '1.0',
    multiplierTypeCode: 'NONE',
    practiceAreas: [],
    totalTimeInSeconds: null,
    totalTimeInHours: null,
    billedTimeInSeconds: 0,
    billedTimeInHours: '0',
};

const TimecardCenterForm = (): JSX.Element => {
    const navigate = useNavigate();
    const { errorAlert } = useAlert();

    const { id: userId, name: userName } = useSelector((state) => state.user);
    const { buttonsDisabled } = useSelector((state) => state.buttons);
    const [searchMyCases, setSearchMyCases] = useState(false);
    const { classifiers } = useClassifiers({
        codes: ['LANGUAGE'],
    });
    const dispatch = useDispatch();

    const [addTimecard] = useAddTimecardMutation();
    const { startTracking } = useStartTrackingTimecard();
    const { handleSubmit, control, setValue, watch } = useForm<TimecardDto>({
        defaultValues,
    });

    useEffect(() => {
        if (userId && userName) {
            setValue('employee', { id: userId, name: userName });
        }
    }, [setValue, userId, userName]);

    const onTotalTimeChange = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
        const hoursValue = event.target.value;

        let secondsValue: number | null = null;
        if (hoursValue && hoursValue.length > 0) {
            // eslint-disable-next-line no-useless-escape
            const hours = parseFloat(hoursValue.split(/[,\.]/).filter(Boolean).join('.'));
            secondsValue = hoursToSeconds(hours);
        }

        setValue('totalTimeInSeconds', secondsValue);
    }, []);

    const currentMatter = watch('matter');
    const { data: lastAddedTimecardPracticeAreas } = useGetLastAddedTimecardPracticeAreasQuery(
        {
            employeeId: userId?.toString() || '',
            matterId: currentMatter?.id || 0,
        },
        { skip: !currentMatter }
    );

    useEffect(() => {
        if (!lastAddedTimecardPracticeAreas) {
            return;
        }

        setValue('practiceAreas', lastAddedTimecardPracticeAreas);
    }, [lastAddedTimecardPracticeAreas, setValue]);

    const { data: mattersList } = useGetMattersListQuery({
        filters: searchMyCases ? `${myCasesFilter};${openAndUnblockedCasesFilter}` : openAndUnblockedCasesFilter,
    });

    const getMatterLabel = useCallback(
        (matter: MatterListItem) =>
            `${matter?.caseNumber || ''}-${matter?.name || ''} - ${matter.clientParty?.name || ''}`,
        []
    );

    const onFinish = (data: TimecardDto) => {
        dispatch(closeSubDrawer());
        navigate(`/timecards/finish/`, {
            state: {
                timecardData: {
                    ...data,
                    description: data?.description ?? '',
                    totalTimeInSeconds: data?.totalTimeInSeconds ?? 0,
                },
            },
        });
    };

    const onCreateDraft = async (data: TimecardDto) => {
        try {
            await addTimecard({
                ...data,
                description: data?.description ?? '',
                totalTimeInSeconds: data?.totalTimeInSeconds ?? 0,
            }).unwrap();
            dispatch(closeSubDrawer());
            navigate(`/timecards`);
        } catch (e) {
            console.error('onSubmit error: ', e);
            errorAlert(t('forms.error-submitting-the-form'));
        }
    };

    const onStartTracking = async (data: TimecardDto) => {
        try {
            const timecard = await addTimecard({
                ...data,
                description: data?.description ?? '',
                totalTimeInSeconds: data?.totalTimeInSeconds ?? 0,
            }).unwrap();

            startTracking(timecard.id).then(() => {
                dispatch(closeSubDrawer());
            });
        } catch (e) {
            console.error('onSubmit error: ', e);
            errorAlert(t('forms.error-submitting-the-form'));
        }
    };

    const onSubmit = async (data: TimecardDto, action: 'createDraft' | 'finish' | 'start'): Promise<void> => {
        if (action === 'createDraft') {
            onCreateDraft(data);
        } else if (action === 'finish') {
            onFinish(data);
        } else if (action === 'start') {
            onStartTracking(data);
        }
    };

    return (
        <>
            <SectionTitle
                title="timecards.create-new-timecard"
                rightComponent={<SearchMyCasesCheckbox value={searchMyCases} onChange={setSearchMyCases} />}
            />
            <SelectInput
                setValue={setValue}
                control={control}
                required
                name="matter"
                options={mattersList || []}
                getOptionLabel={getMatterLabel}
                getOptionIdentifier="id"
                placeholder="timecards.form.select-case"
                placeholderOnFocus="timecards.form.search-case-by"
                sortDirection="asc"
            />
            <TextInput
                name="description"
                control={control}
                placeholder="timecards.form.description"
                suffixComponent={
                    currentMatter?.timecardLanguageCode ? (
                        <Box>
                            <TimecardLanguageLabel
                                classifierData={classifiers?.['LANGUAGE']}
                                timecardLanguageTypeCode={currentMatter.timecardLanguageCode}
                            />
                        </Box>
                    ) : null
                }
            />
            <Grid container spacing={1}>
                <Grid item>
                    <DateInput maxDate={new Date()} control={control} name="timecardDate" required />
                </Grid>
                <Grid item>
                    <HoursInput
                        control={control}
                        name="totalTimeInHours"
                        placeholder="timecards.form.total-time"
                        onChange={onTotalTimeChange}
                    />
                </Grid>
            </Grid>
            <Stack direction="row" gap={1} pt={1} pb={2}>
                <PermissionButton
                    permissions={['CREATE.TIMECARDS']}
                    variant="contained"
                    color="secondary"
                    startIcon={<PlayCircleIcon sx={{ height: 24, width: 24 }} />}
                    onClick={handleSubmit((data) => onSubmit(data, 'start'))}
                    disabled={buttonsDisabled}
                >
                    {t('timecards.start')}
                </PermissionButton>
                <PermissionButton
                    permissions={['CREATE.TIMECARDS']}
                    variant="outlined"
                    color="secondary"
                    startIcon={<StopIcon sx={{ height: 24, width: 24 }} />}
                    onClick={handleSubmit((data) => onSubmit(data, 'finish'))}
                >
                    {t('timecards.finish')}
                </PermissionButton>
                <PermissionButton
                    permissions={['CREATE.TIMECARDS']}
                    variant="outlined"
                    color="secondary"
                    onClick={handleSubmit((data) => onSubmit(data, 'createDraft'))}
                    disabled={buttonsDisabled}
                >
                    {t('timecards.save-as-draft')}
                </PermissionButton>
            </Stack>
        </>
    );
};

export default TimecardCenterForm;
