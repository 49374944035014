import { Box, Stack, Tooltip, useTheme } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { t } from 'utils/getTranslations';

const InfoTooltip = ({ text, isErrored = false }: { text?: string | string[]; isErrored?: boolean }) => {
    const theme = useTheme();

    if (!text || text.length === 0) return <Box />;

    return (
        <Tooltip
            title={
                Array.isArray(text) ? (
                    <Stack gap={1}>
                        {text.map((item) => (
                            <Box key={item}>{t(item)}</Box>
                        ))}
                    </Stack>
                ) : (
                    t(text)
                )
            }
            describeChild
            sx={{ '.MuiTooltip-tooltip': { textAlign: 'center', width: 'fit-content' } }}
        >
            <InfoOutlinedIcon
                sx={{ ml: '1px', height: '1.2rem' }}
                htmlColor={isErrored ? theme.palette.error.main : theme.palette.grey[400]}
            />
        </Tooltip>
    );
};

export default InfoTooltip;
